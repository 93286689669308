/* eslint-disable @typescript-eslint/no-empty-interface */
import {
  ChartWidget,
  CustomDataColumnSettings,
  CustomDataColumnSettingsLocaleLess,
  CustomDataRowObject,
  CustomDataRowObjectLocaleLess,
  DataKey,
  GlobalDisplacementDataPointId,
  GorsDataPointId,
  LocizeLocale,
  WidgetDataType,
} from '@nrcstat-monorepo/config-and-definitions'
import { exhaustiveCheck } from '@nrcstat-monorepo/utils'
import { useMachine } from '@xstate/react'
import produce from 'immer'
import { merge } from 'lodash'
import { createContext, useContext } from 'react'
import { assign, createMachine } from 'xstate'
import { v4 as uuidv4 } from 'uuid'

interface Context {
  widget: ChartWidget
  activeLocale: LocizeLocale
}

type Event =
  | {
      type: 'UPDATE_DATA_POINTS'
      dataPoints: Array<GlobalDisplacementDataPointId | GorsDataPointId>
    }
  | { type: 'UPDATE_DATA_TYPE'; dataType: WidgetDataType }
  | { type: 'UPDATE_COUNTRIES'; countries: Array<string> }
  | { type: 'UPDATE_YEARS'; years: Array<number> }
  | {
      type: 'UPDATE_CUSTOM_DATA'
      columns: Array<CustomDataColumnSettings>
      data: Array<CustomDataRowObject>
    }
  | {
      type: 'UPDATE_LOCALELESS_CUSTOM_DATA'
      columns: Array<CustomDataColumnSettingsLocaleLess>
      data: Array<CustomDataRowObjectLocaleLess>
    }
  | { type: 'UPDATE_TYPE'; widgetType: ChartWidget['type'] }
  | { type: 'CHANGE_ACTIVE_LOCALE'; newLocale: LocizeLocale }
  | {
      type: 'UPDATE_WIDGET_FIELD'
      field:
        | keyof Pick<
            ChartWidget,
            'title' | 'socialMediaDescription' | 'overridingColourScheme'
          >
        | keyof Pick<
            ChartWidget['config'],
            'subtitle' | 'source' | 'linkToSource'
          >
      value: string
    }
  | {
      type: 'UPDATE_WIDGET_FIELD'
      field: keyof Pick<
        ChartWidget,
        | 'enableSocialMediaSharing'
        | 'enablePopup'
        | 'enableColourSchemeOverride'
      >
      value: boolean
    }
  | { type: 'CHANGE_INTERNAL_NAME'; internalName: string }
  | { type: 'REPLACE_WIDGET'; widget: ChartWidget }
  | { type: 'RESET' }

const dataProp1 = ('data_' + uuidv4()) as DataKey
const dataProp2 = ('data_' + uuidv4()) as DataKey

const initialContext: Context = {
  widget: {
    type: 'bar',
    internalName: '',
    title: {},
    config: {
      subtitle: {},
      source: {},
      linkToSource: '',
    },
    dataType: WidgetDataType.custom,
    autoData: {
      dataPoints: [],
      countries: [],
      years: [],
    },
    customData: {
      columns: [
        {
          type: 'text',
          data: 'label',
          columnLabel: {
            'en-GB': 'Column name',
            'nb-NO': 'Kolonnenavn',
            'de-DE': 'Spaltenname',
            'sv-SE': 'Kolumnnamn',
          },
        },
        {
          renderer: 'numeric',
          data: dataProp1,
          columnLabel: {
            'en-GB': 'Column name 1',
            'nb-NO': 'Kolonnenavn 1',
            'de-DE': 'Spaltenname 1',
            'sv-SE': 'Kolumnnamn 1',
          },
        },
        {
          renderer: 'numeric',
          data: dataProp2,
          columnLabel: {
            'en-GB': 'Column name 2',
            'nb-NO': 'Kolonnenavn 2',
            'de-DE': 'Spaltenname 2',
            'sv-SE': 'Kolumnnamn 2',
          },
        },
      ],
      data: [
        {
          id: uuidv4() as string,
          label: {} as Record<LocizeLocale, string>,
          [dataProp1]: 100,
          [dataProp2]: 200,
        } as CustomDataRowObject, // acceptable assertion, see: https://stackoverflow.com/a/69528712
        {
          id: uuidv4() as string,
          label: {} as Record<LocizeLocale, string>,
          [dataProp1]: 300,
          [dataProp2]: 400,
        } as CustomDataRowObject, // acceptable assertion, see: https://stackoverflow.com/a/69528712
      ],
    },
    socialMediaDescription: {},
    enableSocialMediaSharing: true,
    isTemplate: false,
    enablePopup: true,
    enableColourSchemeOverride: false,
    overridingColourScheme: '',
  },
  activeLocale: LocizeLocale['en-GB'],
}

export const chartBuilderMachine = createMachine(
  {
    tsTypes: {} as import('./chart-builder-machine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Event,
    },
    id: 'chartBuilderMachine',
    initial: 'dataEntry',
    context: initialContext,
    states: {
      dataEntry: {
        on: {
          UPDATE_DATA_POINTS: {
            actions: 'updateDataPoints',
          },
          UPDATE_DATA_TYPE: {
            actions: 'updateDataType',
          },
          UPDATE_COUNTRIES: {
            actions: 'updateCountries',
          },
          UPDATE_YEARS: {
            actions: 'updateYears',
          },
          UPDATE_CUSTOM_DATA: {
            actions: 'updateCustomData',
          },
          UPDATE_LOCALELESS_CUSTOM_DATA: {
            actions: 'updateLocalelessCustomData',
          },
          UPDATE_TYPE: {
            actions: 'updateType',
          },
          CHANGE_ACTIVE_LOCALE: {
            actions: 'changeActiveLocale',
          },
          CHANGE_INTERNAL_NAME: {
            actions: 'changeInternalName',
          },
          UPDATE_WIDGET_FIELD: {
            actions: 'updateWidgetField',
          },
          REPLACE_WIDGET: {
            actions: 'replaceWidget',
          },
          RESET: {
            actions: 'reset',
          },
        },
      },
    },
  },
  {
    actions: {
      updateDataPoints: assign((context, { dataPoints }) =>
        produce(context, (draftContext) => {
          draftContext.widget.autoData.dataPoints = dataPoints
        })
      ),
      updateDataType: assign((context, { dataType }) =>
        produce(context, (draftContext) => {
          draftContext.widget.dataType = dataType
        })
      ),
      updateCountries: assign((context, { countries }) =>
        produce(context, (draftContext) => {
          draftContext.widget.autoData.countries = countries
        })
      ),
      updateYears: assign((context, { years }) =>
        produce(context, (draftContext) => {
          draftContext.widget.autoData.years = years
        })
      ),
      updateCustomData: assign((context, { columns, data }) =>
        produce(context, (draftContext) => {
          draftContext.widget.customData.columns = columns
          draftContext.widget.customData.data = data
        })
      ),
      updateLocalelessCustomData: assign((context, { columns, data }) => {
        const newStuff = produce(context, (draftContext) => {
          draftContext.widget.customData.columns = addColumnsLocaleLayer(
            context.activeLocale,
            context.widget.customData.columns,
            columns
          )
          draftContext.widget.customData.data = addDataLocaleLayer(
            context.activeLocale,
            context.widget.customData.data,
            data
          )
        })

        return newStuff
      }),
      updateType: assign((context, { widgetType }) =>
        produce(context, (draftContext) => {
          draftContext.widget.type = widgetType
        })
      ),
      changeActiveLocale: assign((context, { newLocale }) =>
        produce(context, (draftContext) => {
          draftContext.activeLocale = newLocale
        })
      ),
      changeInternalName: assign((context, { internalName }) =>
        produce(context, (draftContext) => {
          draftContext.widget.internalName = internalName
        })
      ),
      updateWidgetField: assign((context, { field, value }) =>
        produce(context, (draftContext) => {
          switch (field) {
            case 'title':
            case 'socialMediaDescription':
              draftContext.widget[field][draftContext.activeLocale] = value
              break

            case 'source':
            case 'subtitle':
              draftContext.widget.config[field][
                draftContext.activeLocale
              ] = value
              break

            case 'linkToSource':
              draftContext.widget.config.linkToSource = value
              break

            case 'enableSocialMediaSharing':
              draftContext.widget.enableSocialMediaSharing = value
              break

            case 'enablePopup':
              draftContext.widget.enablePopup = value
              break

            case 'enableColourSchemeOverride':
              draftContext.widget.enableColourSchemeOverride = value
              break

            case 'overridingColourScheme':
              draftContext.widget.overridingColourScheme = value
              break

            default:
              exhaustiveCheck(field)
          }
        })
      ),
      replaceWidget: assign((context, { widget }) =>
        produce(context, (draftContext) => {
          draftContext.widget = widget
        })
      ),
      reset: assign(() => initialContext),
    },
  }
)

export function useChartBuilderMachine() {
  return useMachine(chartBuilderMachine, { devTools: true })
}

type UseChartBuilderMachineTuple = ReturnType<typeof useChartBuilderMachine>

export const ChartBuilderServiceContext = createContext<null | UseChartBuilderMachineTuple>(
  null
)

export function useChartBuilderService() {
  const chartBuilderService = useContext<null | UseChartBuilderMachineTuple>(
    ChartBuilderServiceContext
  ) as UseChartBuilderMachineTuple

  return chartBuilderService
}

export function addColumnsLocaleLayer(
  locale: LocizeLocale,
  truth: Array<CustomDataColumnSettings>,
  localeLess: Array<CustomDataColumnSettingsLocaleLess>
) {
  let output = truth
  // Remove any columns that've been rmeoved
  output = output.filter(({ data }) =>
    localeLess.map((d) => d.data).includes(data)
  )
  // Add any columns that've been added
  const newItemIndex = localeLess.findIndex(
    ({ data }) => !truth.map((d) => d.data).includes(data)
  )
  if (newItemIndex !== -1) {
    const newItem = localeLess[newItemIndex]
    const localizedNewItem = {
      ...newItem,
      columnLabel: { [locale]: newItem.columnLabel },
    } as CustomDataColumnSettings
    output = output.slice()
    output.splice(newItemIndex, 0, localizedNewItem)
  }
  // Deepmerge the rest
  output = output.map((item, index) => {
    const newItem = merge({}, item, {
      columnLabel: { [locale]: localeLess[index].columnLabel },
    })
    return newItem
  })
  return output
}

export function removeCustomDataLocaleLayer(
  locale: LocizeLocale,
  columns: ChartWidget['customData']['columns'],
  data: ChartWidget['customData']['data']
): [CustomDataColumnSettingsLocaleLess[], CustomDataRowObjectLocaleLess[]] {
  const columnsWithoutLocale = columns.map((column) => ({
    ...column,
    columnLabel: column.columnLabel[locale],
  })) as CustomDataColumnSettingsLocaleLess[]
  const dataWithoutLocale = data.map((d) => ({
    ...d,
    label: d.label[locale],
  })) as CustomDataRowObjectLocaleLess[]

  return [columnsWithoutLocale, dataWithoutLocale]
}

export function addDataLocaleLayer(
  locale: LocizeLocale,
  truth: Array<CustomDataRowObject>,
  localeLess: Array<CustomDataRowObjectLocaleLess>
) {
  let output = truth
  // Remove any dara rows that've been rmeoved
  output = output.filter(({ id }) => localeLess.map((d) => d.id).includes(id))
  // Add any data rows that've been added
  let newItemIndex = localeLess.findIndex(
    ({ id }) => !truth.map((d) => d.id).includes(id)
  )
  while (newItemIndex !== -1) {
    const newItem = localeLess[newItemIndex]
    const localizedNewItem = {
      ...newItem,
      label: { [locale]: newItem.label },
    } as CustomDataRowObject
    output = output.slice()
    output.splice(newItemIndex, 0, localizedNewItem)
    newItemIndex = localeLess.findIndex(
      ({ id }, i) => i > newItemIndex && !truth.map((d) => d.id).includes(id)
    )
  }
  // Deepmerge the rest
  output = output.map((item, index) => {
    const newItem = merge({}, localeLess[index], {
      label: { ...item.label, [locale]: localeLess[index].label },
    }) as CustomDataRowObject
    return newItem
  })
  return output
}
