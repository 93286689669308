import { getAccessToken } from '@nrcstat-monorepo/auth'
import { UnauthorizedException } from '@nrcstat-monorepo/errors'
import { merge } from 'lodash'

export async function apiFetch<ResponseBody>(
  input: RequestInfo,
  init?: RequestInit
): Promise<ResponseBody> {
  const accessToken = await getAccessToken()
  const defaultConfig = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${accessToken}`,
    },
  }
  const mergedConfig = merge({}, defaultConfig, init ?? {})

  const response = await fetch(input, mergedConfig)

  if (response.status === 401) {
    throw new UnauthorizedException(response.statusText)
  }

  const json = (await response.json()) as ResponseBody

  return json
}
