import { mapNestedObjectToPathKeyedObject } from '@nrcstat-monorepo/utils'

export type LocaleCode = string
export type NamespaceId = string
export type LocizeNamespace = Record<string, string>
export type LocizeNamespaceSet = Record<NamespaceId, LocizeNamespace>
export type LocizeLocaleSet = Record<LocaleCode, LocizeNamespaceSet>

async function fetchLocizeNamespace(
  locale: string,
  namespace: string
): Promise<LocizeNamespace> {
  const resp = await fetch(
    `https://api.locize.app/faea19bd-5e3e-4bc5-b91a-721ee4afb475/latest/${locale}/${namespace}`
  )
  const data = await resp.json()
  const normalizedData = mapNestedObjectToPathKeyedObject(data)

  return normalizedData
}

export async function fetchLocizeLocalesNamespaces(
  locales: string[],
  namespaces: string[]
): Promise<LocizeLocaleSet> {
  const localesData: LocizeLocaleSet = {}

  const promises = locales.map((locale) =>
    namespaces.map(async (namespace) => {
      const ns = await fetchLocizeNamespace(locale, namespace)
      if (!localesData[locale]) localesData[locale] = {}
      localesData[locale][namespace] = ns
    })
  )
  await Promise.all(promises)

  return localesData
}
