import {
  Card,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material'
import { useI18n } from '@nrcstat-monorepo/i18n'
import { mergeMeta } from '@nrcstat-monorepo/utils'
import { Layout } from '@nrcstat-monorepo/widget-builder/components'
import {
  useAppNavigationService,
  useChartBuilderService,
} from '@nrcstat-monorepo/widget-builder/machines'
import React from 'react'
import { DataEntry } from './DataEntry'
import { DescribeWidget } from './DescribeWidget'
import { PickWidgetType } from './PickWidgetType'

export function BuildChart() {
  const [navState, navStateSend] = useAppNavigationService()
  const [, chartBuilderSend] = useChartBuilderService()

  const meta = mergeMeta(navState.meta)
  const currentStepIndex = meta.stepIndex as number

  const t = useI18n()

  const onResetWidgetClick = () =>
    window.confirm('Are you sure?') && chartBuilderSend('RESET')

  const onStepClick = (targetStepIndex: number) => {
    const direction = targetStepIndex > currentStepIndex ? 'NEXT' : 'BACK'
    const stepsToTake = Math.abs(targetStepIndex - currentStepIndex)
    for (let i = 0; i < stepsToTake; i++) navStateSend(direction)
  }

  return (
    <Layout>
      <Typography variant="h4" align="center">
        New chart
      </Typography>
      <Stepper
        alternativeLabel
        nonLinear
        activeStep={currentStepIndex}
        sx={{ mb: 5, maxWidth: '35em', mx: 'auto' }}
      >
        {STEPS.map((label, index) => (
          <Step key={index}>
            <StepButton onClick={() => onStepClick(index)}>
              <StepLabel
                sx={{
                  '& .MuiSvgIcon-root': {
                    color: currentStepIndex >= index ? 'primary.main' : 'white',
                  },
                }}
              >
                {label}
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Card>
        <div>
          {navState
            .toStrings()
            .includes('loggedIn.buildChart.steps.dataEntry') ? (
            <DataEntry />
          ) : navState
              .toStrings()
              .includes('loggedIn.buildChart.steps.pickWidgetType') ? (
            <PickWidgetType />
          ) : navState
              .toStrings()
              .includes('loggedIn.buildChart.steps.describeWidget') ? (
            <DescribeWidget />
          ) : null}
        </div>
      </Card>
    </Layout>
  )
}

const STEPS = ['data entry', 'visualization', 'description']
