export const GorsDataPointId = {
  gors_camp: 'gors_camp',
  gors_education: 'gors_education',
  gors_icla: 'gors_icla',
  gors_lfs: 'gors_lfs',
  gors_shelter: 'gors_shelter',
  gors_wash: 'gors_wash',
} as const

export type GorsDataPointId = typeof GorsDataPointId[keyof typeof GorsDataPointId]
