import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
} from '@mui/material'
import {
  ChartWidget,
  isWidgetChartWidget,
  LocizeLocale,
  PictogramWidget,
  TimelineWidget,
  WidgetBase,
} from '@nrcstat-monorepo/config-and-definitions'
import {
  useDeleteWidget,
  useFetchWidget,
  usePutWidget,
} from '@nrcstat-monorepo/react-query/query'
import { RenderIfUserAdmin } from '@nrcstat-monorepo/widget-builder/components'
import {
  useAppNavigationService,
  useChartBuilderService,
  usePictogramBuilderService,
  useTimelineBuilderService,
} from '@nrcstat-monorepo/widget-builder/machines'
import React, { useContext } from 'react'
import { useWidgetPreview } from '../../hooks/use-widget-preview'

export function WidgetInfoActionModal() {
  const [navState, navStateSend] = useAppNavigationService()

  const { data: widget, isSuccess } = useFetchWidget(
    navState.context.selectedWidgetId
  )

  const isOpen =
    navState.matches('loggedIn.main.widgetInfoActionModal.open') && isSuccess

  return (
    <Dialog
      open={isOpen}
      onClose={() => navStateSend('CLOSE_WIDGET_MODAL')}
      fullWidth
    >
      {widget ? (
        <>
          <DialogContent>
            <Stack>
              <WidgetPreview widget={widget} locale="en-GB" />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ width: '100%' }}
            >
              <div>
                <Button onClick={() => navStateSend('CLOSE_WIDGET_MODAL')}>
                  Cancel
                </Button>
                <RenderIfUserAdmin>
                  <DeleteWidgetButton
                    widgetId={String(widget.id)}
                    onDeleteSuccess={() => navStateSend('CLOSE_WIDGET_MODAL')}
                  >
                    Delete
                  </DeleteWidgetButton>
                </RenderIfUserAdmin>
              </div>
              <div>
                <RenderIfUserAdmin>
                  <ToggleTemplateButton widget={widget}>
                    <ToggleTemplateButton.IsTemplate>
                      Unset as template
                    </ToggleTemplateButton.IsTemplate>
                    <ToggleTemplateButton.IsNotTemplate>
                      Set as template
                    </ToggleTemplateButton.IsNotTemplate>
                  </ToggleTemplateButton>
                </RenderIfUserAdmin>
                <UseWidgetToCreateNewWidgetButton widget={widget}>
                  Use for new
                </UseWidgetToCreateNewWidgetButton>
              </div>
            </Stack>
          </DialogActions>
        </>
      ) : null}
    </Dialog>
  )
}

function WidgetPreview({
  widget,
  locale,
}: {
  widget: WidgetBase
  locale: LocizeLocale
}) {
  useWidgetPreview('#widget-container', widget, locale)
  return <div id="widget-container" style={{ width: '100%' }} />
}

interface DeleteWidgetButtonProps {
  widgetId: string
  onDeleteSuccess: () => void
  children: React.ReactNode
}

function DeleteWidgetButton({
  widgetId,
  onDeleteSuccess,
  children,
}: DeleteWidgetButtonProps) {
  const deleteWidget = useDeleteWidget()
  const handleDelete = async () => {
    const confirmed = window.confirm(
      'Are you sure you want to delete this visualisation?'
    )
    if (!confirmed) return
    await deleteWidget.mutate(widgetId)
    onDeleteSuccess()
  }

  return (
    <Button
      onClick={handleDelete}
      disabled={deleteWidget.isLoading}
      variant="contained"
    >
      {children}
    </Button>
  )
}

interface ToggleTemplateButtonProps {
  widget: WidgetBase
  children: React.ReactNode
}

function ToggleTemplateButton({
  widget: { isTemplate, id },
  children,
}: ToggleTemplateButtonProps) {
  const putWidget = usePutWidget()
  const handleToggle = async () => {
    if (!id) return
    await putWidget.mutateAsync({
      widgetId: id,
      updates: { isTemplate: !isTemplate },
    })
  }
  return (
    <ToggleTemplateButtonContext.Provider value={{ isTemplate }}>
      <Button variant="contained" onClick={() => handleToggle()}>
        {children}
      </Button>
    </ToggleTemplateButtonContext.Provider>
  )
}
const ToggleTemplateButtonContext = React.createContext({
  isTemplate: undefined,
} as {
  isTemplate?: boolean
})
interface SubComponentProps {
  children: React.ReactNode
}
function IsTemplate({ children }: SubComponentProps) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isTemplate } = useContext(ToggleTemplateButtonContext)

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return isTemplate ? <>{children}</> : null
}
function IsNotTemplate({ children }: SubComponentProps) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isTemplate } = useContext(ToggleTemplateButtonContext)

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return isTemplate ? null : <>{children}</>
}
ToggleTemplateButton.IsTemplate = IsTemplate
ToggleTemplateButton.IsNotTemplate = IsNotTemplate

export function useCreateNewWidgetFromWidget(widget: WidgetBase) {
  const [, navStateSend] = useAppNavigationService()
  const [, chartBuilderSend] = useChartBuilderService()
  const [, pictogramBuilderSend] = usePictogramBuilderService()
  const [, timelineBuilderSend] = useTimelineBuilderService()

  const handleClick = () => {
    const newWidget = JSON.parse(JSON.stringify(widget)) as WidgetBase

    delete newWidget.id
    newWidget.isTemplate = false
    delete newWidget.dateCreated

    switch (newWidget.type) {
      case 'bar':
      case 'column':
      case 'donut':
      case 'line':
        chartBuilderSend({
          type: 'REPLACE_WIDGET',
          widget: newWidget as ChartWidget,
        })
        navStateSend(['CLOSE_WIDGET_MODAL', 'BUILD_CHART'])
        break

      case 'pictogram':
        pictogramBuilderSend({
          type: 'REPLACE_WIDGET',
          widget: newWidget as PictogramWidget,
        })
        navStateSend(['CLOSE_WIDGET_MODAL', 'BUILD_PICTOGRAM'])
        break

      case 'timeline':
        timelineBuilderSend({
          type: 'REPLACE_WIDGET',
          widget: newWidget as TimelineWidget,
        })
        navStateSend(['CLOSE_WIDGET_MODAL', 'BUILD_TIMELINE'])
        break
    }
  }

  return handleClick
}

function UseWidgetToCreateNewWidgetButton({
  widget,
  children,
}: {
  widget: WidgetBase
  children: React.ReactNode
}) {
  const handleClick = useCreateNewWidgetFromWidget(widget)

  return (
    <Button variant="contained" onClick={() => handleClick()}>
      {children}
    </Button>
  )
}
