import { deleteWidget } from '@nrcstat-monorepo/api'
import { useMutation, useQueryClient } from 'react-query'

export function useDeleteWidget() {
  const queryClient = useQueryClient()

  return useMutation(deleteWidget, {
    onSuccess: () => queryClient.invalidateQueries('widgets'),
  })
}
