import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import {
  AppBar,
  Avatar,
  Button,
  Container,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useAppNavigationService } from '@nrcstat-monorepo/widget-builder/machines'
import React from 'react'
import NrcLogo from '../assets/nrc-logo.png'
import { logout, useIsLoggedIn } from '@nrcstat-monorepo/auth'

interface Props {
  children: React.ReactChild | React.ReactChild[]
}

const useStyles = makeStyles({
  appBar: {
    backgroundColor: 'white',
  },
  title: {
    marginLeft: 14,
    flexGrow: 1,
    color: '#000',
  },
})

export function Layout({ children }: Props) {
  const [, navStateSend] = useAppNavigationService()

  const classes = useStyles()

  const handleLogout = () => {
    logout()
    navStateSend('LOGOUT')
  }

  const { data: isLoggedIn } = useIsLoggedIn()

  return (
    <>
      <AppBar position="static" className={classes.appBar}>
        <Container maxWidth="lg">
          <Toolbar>
            <Avatar src={NrcLogo} variant="square" />
            <Typography variant="h5" className={classes.title}>
              Visualisation Builder
            </Typography>
            {isLoggedIn ? (
              <>
                <a
                  href="https://loom.com/share/folder/f1cd9e1efdcd4bf4988ca557262f7367"
                  target="_blank"
                  rel="noreferrer"
                >
                  <QuestionMarkIcon />
                </a>
                <Button color="primary" onClick={handleLogout}>
                  Log out
                </Button>
              </>
            ) : null}
          </Toolbar>
        </Container>
      </AppBar>
      <Container maxWidth="lg" sx={{ pb: 10 }}>
        {children}
      </Container>
    </>
  )
}
