import { LocizeLocale } from '../locize-locale'
import { WidgetBase } from './widget-base'

export interface TimelineEntry {
  uniqKey: string
  title: Partial<Record<LocizeLocale, string>>
  subtitle: Partial<Record<LocizeLocale, string>>
  body: Partial<Record<LocizeLocale, string>>
}

export interface TimelineWidget extends WidgetBase {
  type: 'timeline'
  entries: Array<TimelineEntry>
}

export function isWidgetTimelineWidget(
  widget?: WidgetBase
): widget is TimelineWidget {
  return widget?.type === 'timeline'
}
