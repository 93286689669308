import { postWidget } from '@nrcstat-monorepo/api'
import { WidgetBase } from '@nrcstat-monorepo/config-and-definitions'
import { useMutation, useQueryClient } from 'react-query'

interface Error {
  foo: string
}

export function usePostWidgetMutation<Widget extends WidgetBase>() {
  const queryClient = useQueryClient()

  return useMutation<Widget, Error, Widget, unknown>(
    (newWidget: Widget) => postWidget<Widget>(newWidget),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('widgets')
      },
    }
  )
}
