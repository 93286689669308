import {
  Box,
  Button,
  CardContent,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import {
  LocizeLocale,
  LOCIZE_ALL_LOCALES,
} from '@nrcstat-monorepo/config-and-definitions'
import { TabPanel } from '@nrcstat-monorepo/widget-builder/components'
import {
  useAppNavigationService,
  useChartBuilderService,
} from '@nrcstat-monorepo/widget-builder/machines'
import React, { useRef } from 'react'
import { useWidgetPreview } from '../../hooks/use-widget-preview'
import { determineIsWidgetCompatibleWithColumns } from './CustomData'

export const PickWidgetTypeTab = {
  column: 'column',
  bar: 'bar',
  donut: 'donut',
  line: 'line',
  table: 'table',
} as const
export type PickWidgetTypeTab = typeof PickWidgetTypeTab[keyof typeof PickWidgetTypeTab]

export function PickWidgetType() {
  const [, navStateSend] = useAppNavigationService()
  const [chartBuilder, chartBuilderSend] = useChartBuilderService()

  const widgetContainerElementRef = useRef<HTMLDivElement | null>()

  const { widget, activeLocale } = chartBuilder.context

  useWidgetPreview('#widget-container', widget, activeLocale)

  const currentTab = widget.type

  const onWidgetTypeTabChange = (_: unknown, newTab: PickWidgetTypeTab) =>
    chartBuilderSend('UPDATE_TYPE', { widgetType: newTab })
  const onLocaleTabChange = (_: unknown, newTab: LocizeLocale) =>
    chartBuilderSend('CHANGE_ACTIVE_LOCALE', { newLocale: newTab })

  const isWidgetNotCompatible =
    (widget.type === 'table' && widget.dataType === 'auto') ||
    (widget.dataType === 'custom' &&
      !determineIsWidgetCompatibleWithColumns(
        widget.customData.columns,
        widget.type
      ))

  return (
    <>
      <Tabs
        value={currentTab}
        onChange={onWidgetTypeTabChange}
        indicatorColor="primary"
      >
        <Tab label="Bar" value={PickWidgetTypeTab.bar} />
        <Tab label="Column" value={PickWidgetTypeTab.column} />
        <Tab label="Donut" value={PickWidgetTypeTab.donut} />
        <Tab label="Line" value={PickWidgetTypeTab.line} />
        <Tab label="Table" value={PickWidgetTypeTab.table} />
      </Tabs>
      <CardContent>
        <Tabs value={activeLocale} onChange={onLocaleTabChange}>
          {LOCIZE_ALL_LOCALES.map((locale) => (
            <Tab key={locale} label={locale.toUpperCase()} value={locale} />
          ))}
        </Tabs>
        {['bar', 'column', 'donut', 'line', 'table'].map((type) => (
          <TabPanel key={type} value={currentTab} index={type}>
            {isWidgetNotCompatible ? (
              <Paper
                sx={{
                  width: '100%',
                  height: '15em',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography sx={{ color: 'text.secondary' }} variant="h6">
                  This visualisation cannot be used with selected data
                </Typography>
              </Paper>
            ) : null}
            <div
              id="widget-container"
              ref={(ref) => (widgetContainerElementRef.current = ref)}
              style={{ display: isWidgetNotCompatible ? 'none' : 'block' }}
            />
          </TabPanel>
        ))}
      </CardContent>
      <CardContent sx={{ mt: 3 }}>
        <Grid container justifyContent="space-between">
          <Button
            onClick={() => navStateSend('BACK')}
            color="primary"
            variant="outlined"
          >
            Back
          </Button>
          <Button
            onClick={() => navStateSend('NEXT')}
            variant="outlined"
            color="primary"
          >
            Next
          </Button>
        </Grid>
        <Box
          sx={{
            mt: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Button>Reset form</Button>
          <Button onClick={() => navStateSend('CANCEL')}>
            Back to main menu
          </Button>
        </Box>
      </CardContent>
    </>
  )
}
