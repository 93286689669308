/* eslint-disable @typescript-eslint/no-empty-interface */
import {
  LocizeLocale,
  TimelineEntry,
  TimelineWidget,
} from '@nrcstat-monorepo/config-and-definitions'
import { useMachine } from '@xstate/react'
import produce from 'immer'
import { createContext, useContext } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { assign, createMachine } from 'xstate'

interface Context {
  widget: TimelineWidget
  activeLocale: LocizeLocale
}

const initialContext: Context = {
  widget: {
    internalName: '',
    title: {},
    socialMediaDescription: {},
    type: 'timeline',
    entries: [{ uniqKey: uuidv4(), title: {}, subtitle: {}, body: {} }],
    enableSocialMediaSharing: false,
    isTemplate: false,
  },
  activeLocale: LocizeLocale['en-GB'],
}

type Event =
  | { type: 'CHANGE_ACTIVE_LOCALE'; newLocale: LocizeLocale }
  | { type: 'CHANGE_INTERNAL_NAME'; internalName: string }
  | { type: 'ENTRY_ADD' }
  | {
      type: 'ENTRY_UPDATE'
      uniqKey: string
      field: keyof Omit<TimelineEntry, 'uniqKey'>
      value: string
    }
  | { type: 'ENTRY_REMOVE'; uniqKey: string }
  | { type: 'ENTRY_REORDER'; direction: 'up' | 'down'; index: number }
  | { type: 'REPLACE_WIDGET'; widget: TimelineWidget }
  | { type: 'RESET' }

export const timelineBuilderMachine = createMachine(
  {
    tsTypes: {} as import('./timeline-builder-machine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Event,
    },
    id: 'timelineBuilderMachine',
    initial: 'dataEntry',
    context: initialContext,
    states: {
      dataEntry: {
        on: {
          CHANGE_ACTIVE_LOCALE: {
            actions: 'changeActiveLocale',
          },
          CHANGE_INTERNAL_NAME: {
            actions: 'changeInternalName',
          },
          ENTRY_ADD: {
            actions: 'entryAdd',
          },
          ENTRY_UPDATE: {
            actions: 'entryUpdate',
          },
          ENTRY_REMOVE: {
            actions: 'entryRemove',
          },
          ENTRY_REORDER: {
            actions: 'entryReorder',
          },
          REPLACE_WIDGET: {
            actions: 'replaceWidget',
          },
          RESET: {
            actions: 'reset',
          },
        },
      },
    },
  },
  {
    actions: {
      changeActiveLocale: assign({
        activeLocale: (_, { newLocale }) => newLocale,
      }),
      changeInternalName: assign((context, { internalName }) =>
        produce(context, (draftContext) => {
          draftContext.widget.internalName = internalName
          return draftContext
        })
      ),
      entryAdd: assign((context) =>
        produce(context, (draftContext) => {
          draftContext.widget.entries.push({
            uniqKey: uuidv4(),
            title: {},
            subtitle: {},
            body: {},
          })
          return draftContext
        })
      ),
      entryUpdate: assign((context, { uniqKey, field, value }) =>
        produce(context, (draftContext) => {
          const index = draftContext.widget.entries.findIndex(
            (entry) => entry.uniqKey === uniqKey
          )
          if (index === -1) return draftContext
          draftContext.widget.entries[index][field][
            draftContext.activeLocale
          ] = value
          return draftContext
        })
      ),
      entryRemove: assign((context, { uniqKey }) =>
        produce(context, (draftContext) => {
          draftContext.widget.entries = draftContext.widget.entries.filter(
            (entry) => entry.uniqKey !== uniqKey
          )
          return draftContext
        })
      ),
      entryReorder: assign((context, { direction, index }) =>
        produce(context, (draftContext) => {
          const destinationIndex = direction === 'up' ? index - 1 : index + 1
          const element = draftContext.widget.entries.splice(index, 1)[0]
          draftContext.widget.entries.splice(destinationIndex, 0, element)
          return draftContext
        })
      ),
      replaceWidget: assign({
        widget: (_, { widget }) => widget,
      }),
      reset: assign(() => initialContext),
    },
  }
)

export function useTimelineBuilderMachine() {
  return useMachine(timelineBuilderMachine, { devTools: true })
}

type UseTimelineBuilderMachineTuple = ReturnType<
  typeof useTimelineBuilderMachine
>

export const TimelineBuilderServiceContext = createContext<null | UseTimelineBuilderMachineTuple>(
  null
)

export function useTimelineBuilderService() {
  const timelineBuilderService = useContext<null | UseTimelineBuilderMachineTuple>(
    TimelineBuilderServiceContext
  ) as UseTimelineBuilderMachineTuple

  return timelineBuilderService
}
