import {
  DATA_API_URL,
  WidgetBase,
} from '@nrcstat-monorepo/config-and-definitions'
import { apiFetch } from './utils/api-fetch'

export async function putWidget<Widget extends WidgetBase>({
  widgetId,
  updates,
}: {
  widgetId: string
  updates: Partial<Widget>
}): Promise<Widget> {
  const updatedWidget = await apiFetch<Widget>(
    `${DATA_API_URL}/widgets/${widgetId}`,
    {
      method: 'PUT',
      body: JSON.stringify(updates),
    }
  )

  return updatedWidget
}
