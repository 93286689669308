/* eslint-disable @typescript-eslint/no-empty-interface */
import {
  BackgroundColorKey,
  DataColor,
  LocizeLocale,
  PictogramSectionIcon,
  PictogramWidget,
} from '@nrcstat-monorepo/config-and-definitions'
import { useMachine } from '@xstate/react'
import produce from 'immer'
import { createContext, useContext } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { assign, createMachine } from 'xstate'

interface Context {
  widget: PictogramWidget
  activeLocale: LocizeLocale
}

type Event =
  | { type: 'CHANGE_ACTIVE_LOCALE'; newLocale: LocizeLocale }
  | {
      type: 'UPDATE_WIDGET_FIELD'
      field: keyof Pick<
        PictogramWidget,
        'title' | 'subtitle' | 'source' | 'socialMediaDescription'
      >
      value: string
    }
  | {
      type: 'UPDATE_WIDGET_FIELD'
      field: keyof Pick<PictogramWidget, 'enableSocialMediaSharing'>
      value: boolean
    }
  | { type: 'CHANGE_INTERNAL_NAME'; internalName: string }
  | { type: 'SECTION_ADD' }
  | { type: 'SECTION_UPDATE_TITLE'; uniqKey: string; title: string }
  | { type: 'SECTION_REMOVE'; uniqKey: string }
  | { type: 'SECTION_REORDER'; direction: 'up' | 'down'; index: number }
  | { type: 'REPLACE_WIDGET'; widget: PictogramWidget }
  | { type: 'RESET' }
  | { type: 'SECTION_ADD_ICON'; sectionKey: string }
  | { type: 'SECTION_REMOVE_ICON'; sectionKey: string; iconKey: string }
  | {
      type: 'SECTION_ICON_FIELD_CHANGE'
      sectionKey: string
      iconKey: string
      field: keyof Omit<PictogramSectionIcon, 'uniqKey'>
      value: string | number
    }

const initialContext: Context = {
  widget: {
    internalName: '',
    title: {},
    subtitle: {},
    source: {},
    socialMediaDescription: {},
    backgroundColor: BackgroundColorKey.White,
    type: 'pictogram',
    sections: [{ uniqKey: uuidv4(), title: {}, icons: [] }],
    enableSocialMediaSharing: true,
    isTemplate: false,
  },
  activeLocale: LocizeLocale['en-GB'] as LocizeLocale,
}

export const pictogramBuilderMachine = createMachine(
  {
    tsTypes: {} as import('./pictogram-builder-machine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Event,
    },
    id: 'pictogramBuilderMachine',
    initial: 'dataEntry',
    context: initialContext,
    states: {
      dataEntry: {
        on: {
          CHANGE_ACTIVE_LOCALE: {
            actions: 'changeActiveLocale',
          },
          UPDATE_WIDGET_FIELD: {
            actions: 'updateWidgetField',
          },
          CHANGE_INTERNAL_NAME: {
            actions: 'changeInternalName',
          },
          SECTION_ADD: {
            actions: 'sectionAdd',
          },
          SECTION_UPDATE_TITLE: {
            actions: 'sectionUpdateTitle',
          },
          SECTION_REMOVE: {
            actions: 'sectionRemove',
          },
          SECTION_REORDER: {
            actions: 'sectionReorder',
          },
          REPLACE_WIDGET: {
            actions: 'replaceWidget',
          },
          RESET: {
            actions: 'reset',
          },
          SECTION_ADD_ICON: {
            actions: 'sectionAddIcon',
          },
          SECTION_REMOVE_ICON: {
            actions: 'sectionRemoveIcon',
          },
          SECTION_ICON_FIELD_CHANGE: {
            actions: 'sectionIconFieldChange',
          },
        },
      },
    },
  },
  {
    actions: {
      changeActiveLocale: assign({
        activeLocale: (_, { newLocale }) => newLocale,
      }),
      updateWidgetField: assign((context, { field, value }) =>
        produce(context, (draftContext) => {
          if (field === 'enableSocialMediaSharing') {
            draftContext.widget[field] = value
          } else {
            draftContext.widget[field][draftContext.activeLocale] = value
          }
          return draftContext
        })
      ),
      changeInternalName: assign((context, { internalName }) =>
        produce(context, (draftContext) => {
          draftContext.widget.internalName = internalName
          return draftContext
        })
      ),
      sectionAdd: assign((context) =>
        produce(context, (draftContext) => {
          draftContext.widget.sections.push({
            uniqKey: uuidv4(),
            title: {},
            icons: [
              {
                uniqKey: uuidv4(),
                icon: undefined,
                data: 0,
                dataColor: DataColor.Orange,
              },
            ],
          })
          return draftContext
        })
      ),
      sectionUpdateTitle: assign((context, { uniqKey, title }) =>
        produce(context, (draftContext) => {
          const index = draftContext.widget.sections.findIndex(
            (entry) => entry.uniqKey === uniqKey
          )
          if (index === -1) return draftContext
          draftContext.widget.sections[index].title[
            draftContext.activeLocale
          ] = title
          return draftContext
        })
      ),
      sectionRemove: assign((context, { uniqKey }) =>
        produce(context, (draftContext) => {
          draftContext.widget.sections = draftContext.widget.sections.filter(
            (entry) => entry.uniqKey !== uniqKey
          )
          return draftContext
        })
      ),
      sectionReorder: assign((context, { direction, index }) =>
        produce(context, (draftContext) => {
          const destinationIndex = direction === 'up' ? index - 1 : index + 1
          const element = draftContext.widget.sections.splice(index, 1)[0]
          draftContext.widget.sections.splice(destinationIndex, 0, element)
          return draftContext
        })
      ),

      replaceWidget: assign({
        widget: (_, { widget }) => widget,
      }),
      reset: assign(() => initialContext),
      sectionAddIcon: assign((context, { sectionKey }) =>
        produce(context, (draftContext) => {
          const index = draftContext.widget.sections.findIndex(
            (entry) => entry.uniqKey === sectionKey
          )
          if (index === -1) return draftContext
          draftContext.widget.sections[index].icons.push({
            uniqKey: uuidv4(),
            icon: undefined,
            data: 0,
            dataColor: DataColor.Orange,
          })
          return draftContext
        })
      ),
      sectionRemoveIcon: assign((context, { sectionKey, iconKey }) =>
        produce(context, (draftContext) => {
          const index = draftContext.widget.sections.findIndex(
            (entry) => entry.uniqKey === sectionKey
          )
          if (index === -1) return draftContext
          draftContext.widget.sections[
            index
          ].icons = draftContext.widget.sections[index].icons.filter(
            (icon) => icon.uniqKey !== iconKey
          )
          return draftContext
        })
      ),
      sectionIconFieldChange: assign(
        (context, { sectionKey, iconKey, field, value }) =>
          produce(context, (draftContext) => {
            const sectionIndex = draftContext.widget.sections.findIndex(
              (entry) => entry.uniqKey === sectionKey
            )
            if (sectionIndex === -1) return draftContext
            const iconIndex = draftContext.widget.sections[
              sectionIndex
            ].icons.findIndex((entry) => entry.uniqKey === iconKey)
            if (iconIndex === -1) return draftContext
            if (field === 'icon' || field === 'dataColor') {
              // TODO: fix typing
              if (typeof value === 'string') {
                ;(draftContext.widget.sections[sectionIndex].icons[iconIndex][
                  field
                ] as any) = value
              }
            } else if (field === 'data') {
              // TODO: fix typing
              ;(draftContext.widget.sections[sectionIndex].icons[iconIndex][
                field
              ] as any) = value as number
            }
            return draftContext
          })
      ),
    },
  }
)

export function usePictogramBuilderMachine() {
  return useMachine(pictogramBuilderMachine, { devTools: true })
}

type UsePictogramBuilderMachinTuple = ReturnType<
  typeof usePictogramBuilderMachine
>

export const PictogramBuilderServiceContext = createContext<null | UsePictogramBuilderMachinTuple>(
  null
)

export function usePictogramBuilderService() {
  const pictogramBuilderService = useContext<null | UsePictogramBuilderMachinTuple>(
    PictogramBuilderServiceContext
  ) as UsePictogramBuilderMachinTuple

  return pictogramBuilderService
}
