export const DATA_API_URL = 'https://data.nrcdata.no/api'
export const RAZZLE_API_URL = 'https://razzle.nrcdata.no'
export const LOCIZE_NAMESPACES_CACHE_TIME = 24 * 60 * 60 * 1000 // 24 hours
export const LOCIZE_ALL_LOCALE_NAMESPACES = [
  'GeographicalNames',
  'Glossary',
  'VisualisationBuilder.UserInterfaceText',
  'Widget.Static.CountryDashboard',
  'Widget.Static.GlobalRadialBarChartDisplacementMap',
  'Widget.Static.Table',
  'Widget.Static.GlobalRadialBarChartDisplacementMap.ADMIN-SETTINGS-ONLY-ADMINS-TOUCH-THIS',
]

export const AUTH0_DOMAIN = 'nrcdata.eu.auth0.com'
export const AUTH0_CLIENTID = 'b3ICo9wNgp7J0CymHSIah1y596gwjsdp'
