import ContentCopyIcon from '@mui/icons-material/ContentCopy'

import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import {
  GlobalDisplacementDataPointId,
  GorsDataPointId,
  Years,
} from '@nrcstat-monorepo/config-and-definitions'
import { useI18n } from '@nrcstat-monorepo/i18n'
import { useCountries } from '@nrcstat-monorepo/react-query/query'
import { Layout, TabPanel } from '@nrcstat-monorepo/widget-builder/components'
import { useAppNavigationService } from '@nrcstat-monorepo/widget-builder/machines'
import React, { useRef, useState } from 'react'
import { useList } from 'react-use'
import { useWidgetPreview } from '../../hooks/use-widget-preview'

const globalDisplacementDataPoints = Object.values(
  GlobalDisplacementDataPointId
)
const gorsDataPoints = Object.values(GorsDataPointId)

const allDataPointIds = [...globalDisplacementDataPoints, ...gorsDataPoints]

export function PrepareCountrySidebarId() {
  const t = useI18n()

  const [navState, navStateSend] = useAppNavigationService()
  const [countryCode, setCountryCode] = useState('YE')
  const [year, setYear] = useState(2020)
  const [dataPointsToggleList, { updateAt }] = useList(
    new Array(allDataPointIds.length).fill(false)
  )
  const enabledDataPoints = allDataPointIds
    .map((dp, i) => (dataPointsToggleList[i] ? dp : false))
    .filter((dp) => dp) as Array<
    GlobalDisplacementDataPointId | GorsDataPointId
  >

  const { data: countries, isSuccess: countryDataLoaded } = useCountries()

  const widgetId = toCountrySidebarId(countryCode, year, enabledDataPoints)

  // useWidgetPreview('#widget-container', widget, activeLocale)

  const inputElementRef = useRef<HTMLInputElement>()
  const onCopyClick = () => {
    inputElementRef.current?.select()
    document.execCommand('copy')
  }

  const widgetContainerElementRef = useRef<HTMLDivElement | null>()

  if (!countryDataLoaded || !countries) return null

  return (
    <Layout>
      <Typography variant="h4" align="center">
        Prepare Country Sidebar ID
      </Typography>
      <Card>
        <CardContent>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h4" sx={{ mt: 0 }}>
                Data points
              </Typography>
              <Typography variant="h6">Global displacement</Typography>
              <FormControl>
                <FormGroup>
                  {globalDisplacementDataPoints.map((dataPointId, index) => (
                    <FormControlLabel
                      key={dataPointId}
                      control={
                        <Checkbox
                          checked={dataPointsToggleList[index]}
                          onChange={() =>
                            updateAt(index, !dataPointsToggleList[index])
                          }
                        />
                      }
                      label={
                        t(
                          `Glossary:dataPoint.${dataPointId}.shortLabel`
                        ) as string
                      }
                    />
                  ))}
                </FormGroup>
              </FormControl>
              <Typography variant="h5" sx={{ mt: 3 }}>
                GORS data
              </Typography>
              <FormControl>
                <FormGroup>
                  {gorsDataPoints.map((dataPointId, index) => (
                    <FormControlLabel
                      key={dataPointId}
                      control={
                        <Checkbox
                          checked={
                            dataPointsToggleList[
                              index + globalDisplacementDataPoints.length
                            ]
                          }
                          onChange={() => {
                            updateAt(
                              index + globalDisplacementDataPoints.length,
                              !dataPointsToggleList[
                                index + globalDisplacementDataPoints.length
                              ]
                            )
                          }}
                        />
                      }
                      label={
                        t(
                          `Glossary:dataPoint.${dataPointId}.shortLabel`
                        ) as string
                      }
                    />
                  ))}
                </FormGroup>
              </FormControl>

              <Typography variant="h4">Country selection</Typography>
              <FormControl variant="filled" fullWidth>
                <InputLabel>Select a country</InputLabel>
                <Select
                  fullWidth
                  value={countryCode}
                  onChange={(e) => setCountryCode(e.target.value)}
                >
                  {countries.map(({ code2, name }) => (
                    <MenuItem key={code2} value={code2}>
                      {name}
                      {/* {t(
                    `GeographicalNames:NRC.Web.StaticTextDictionary.Contries.${code2}`
                  )} */}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Typography variant="h4">Year selection</Typography>
              <Typography variant="subtitle1">
                Select all years for which you want to show data
              </Typography>
              <FormControl>
                <RadioGroup row>
                  {Years.map((_year) => (
                    <FormControlLabel
                      key={_year}
                      control={
                        <Radio
                          checked={year === _year}
                          onChange={() => setYear(_year)}
                        />
                      }
                      label={String(_year)}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Stack direction="column" justifyItems="center" spacing="20px">
                <span>Visualisation ID:</span>
                <TextField
                  InputLabelProps={{ shrink: false }}
                  value={widgetId}
                  style={{ fontFamily: 'Courier New' }}
                  // style={{ width: '300px' }}
                  multiline
                  rows={5}
                  size="small"
                  inputRef={inputElementRef}
                />
                <IconButton onClick={onCopyClick} size="large">
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Stack>
              <div
                id="widget-container"
                ref={(ref) => (widgetContainerElementRef.current = ref)}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Stack direction="row" justifyContent="space-between">
            <Button
              onClick={() => navStateSend('BACK')}
              variant="outlined"
              color="primary"
            >
              Back
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Layout>
  )
}

function toCountrySidebarId(
  countryCode: string,
  year: number,
  dataPoints: Array<GlobalDisplacementDataPointId | GorsDataPointId>
) {
  let id = `country_sidebar_${countryCode}_${year}`
  if (dataPoints.length > 0) id += `_${dataPoints.join(',')}`

  return id
}
