import { getCurrentUser } from '@nrcstat-monorepo/auth'
import {
  DATA_API_URL,
  WidgetBase,
} from '@nrcstat-monorepo/config-and-definitions'
import { apiFetch } from './utils/api-fetch'

export async function postWidget<Widget extends WidgetBase>(
  widget: Widget
): Promise<Widget> {
  const user = await getCurrentUser()
  const finalWidget = Object.assign({}, widget, { createdBy: user?.name })

  const insertedWidget = await apiFetch<Widget>(`${DATA_API_URL}/widgets`, {
    method: 'POST',
    body: JSON.stringify(finalWidget),
  })

  return insertedWidget
}
