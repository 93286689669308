import { useEffect } from 'react'
import { useDebounce } from './use-debounce'
import { renderWidget } from '@nrcstat-monorepo/widget-social-media-sharing'
import {
  LocizeLocale,
  WidgetBase,
} from '@nrcstat-monorepo/config-and-definitions'

export function useWidgetPreview(
  targetSelector: string,
  widgetObject: WidgetBase,
  locale: LocizeLocale
) {
  const debouncedWidgetObject = useDebounce(widgetObject, 700)
  useEffect(() => {
    renderWidget(targetSelector, debouncedWidgetObject, locale)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    targetSelector,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...objectValuesExcept(debouncedWidgetObject, ['internalName']),
    locale,
  ])
}

function objectValuesExcept(obj: any, except: string[]) {
  return Object.keys(obj)
    .filter((key) => !except.includes(key))
    .map((key) => obj[key])
}
