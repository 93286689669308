import {
  Backdrop,
  CircularProgress,
  createTheme,
  CssBaseline,
  StyledEngineProvider,
  Theme,
  ThemeOptions,
  ThemeProvider,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { useIsLoggedIn } from '@nrcstat-monorepo/auth'
import { useI18n } from '@nrcstat-monorepo/i18n'
import { queryClient } from '@nrcstat-monorepo/react-query/query'
import { RootLevelErrorBoundary } from '@nrcstat-monorepo/widget-builder/components'
import {
  AppNavigationServiceContext,
  ChartBuilderServiceContext,
  PictogramBuilderServiceContext,
  TimelineBuilderServiceContext,
  useAppNavigationMachine,
  useAppNavigationService,
  useChartBuilderMachine,
  usePictogramBuilderMachine,
  useTimelineBuilderMachine,
} from '@nrcstat-monorepo/widget-builder/machines'
import { inspect } from '@xstate/inspect'
import { useEffect } from 'react'
import { useErrorHandler } from 'react-error-boundary'
import {
  QueryClientProvider,
  useIsFetching,
  useIsMutating,
  useQueryClient,
} from 'react-query'
import { BuildChart } from '../pages/buildChart/BuildChart'
import { BuildPictogram } from '../pages/buildPictogram/BuildPictogram'
import { BuildTimeline } from '../pages/buildTimeline/BuildTimeline'
import { LoginForm } from '../pages/loggedOut/LoginForm'
import { Main } from '../pages/main/Main'
import { PrepareCountrySidebarId } from '../pages/prepareCountrySidebarId/PrepareCountrySidebarId'

process.env.NODE_ENV === 'development' && inspect({ iframe: false })

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const MAIN_COLOUR = '#FD5A00'

const themeBase = createTheme({
  palette: {
    primary: {
      main: MAIN_COLOUR,
      contrastText: '#fff',
    },
    secondary: {
      main: '#FDC82F',
    },
    tonalOffset: 0.2,
  },
})

const themeSecond = createTheme(themeBase, {
  components: {
    MuiTypography: {
      styleOverrides: {
        h4: {
          marginTop: themeBase.spacing(4),
          marginBottom: themeBase.spacing(3),
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.MuiButton-contained, &.MuiButton-outlined': {
            height: '48px',
            textAlign: 'center',
            paddingLeft: '36px',
            paddingRight: '36px',
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          '&.Mui-active': {
            fontWeight: 'bold',
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          width: '1.3em',
          height: '1.3em',
          border: '1px solid #FD5A00',
          borderRadius: '50%',
          boxShadow:
            // This line is literally copied (via DevTools) from the header's computed box-shadow property
            '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
        },
        text: {
          fill: 'black',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: grey[400],
        },
        flexContainer: {
          justifyContent: 'space-around',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          flexGrow: 1,
          color: grey[900],
          '&.Mui-selected': {
            backgroundColor: MAIN_COLOUR,
            color: grey[900],
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: themeBase.spacing(3),
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          flexDirection: 'row-reverse',
          backgroundColor: grey[600],
          color: 'white',
        },
        expandIconWrapper: {
          transform: 'rotate(-90deg)',
          '&.Mui-expanded': {
            transform: 'rotate(0deg)',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          backgroundColor: grey[200],
        },
      },
    },
  },
} as ThemeOptions)

const theme = themeSecond

export function App() {
  const navMachineTuple = useAppNavigationMachine()
  const pictogramBuilderMachineTuple = usePictogramBuilderMachine()
  const timelineBuilderMachineTuple = useTimelineBuilderMachine()
  const chartBuilderMachineTuple = useChartBuilderMachine()

  return (
    <>
      <CssBaseline />

      <QueryClientProvider client={queryClient}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <ChartBuilderServiceContext.Provider
              value={chartBuilderMachineTuple}
            >
              <PictogramBuilderServiceContext.Provider
                value={pictogramBuilderMachineTuple}
              >
                <TimelineBuilderServiceContext.Provider
                  value={timelineBuilderMachineTuple}
                >
                  <AppNavigationServiceContext.Provider value={navMachineTuple}>
                    <RootLevelErrorBoundary>
                      <AppChild />
                    </RootLevelErrorBoundary>
                  </AppNavigationServiceContext.Provider>
                </TimelineBuilderServiceContext.Provider>
              </PictogramBuilderServiceContext.Provider>
            </ChartBuilderServiceContext.Provider>
          </ThemeProvider>
        </StyledEngineProvider>
      </QueryClientProvider>
    </>
  )
}

export function AppChild() {
  const handleError = useErrorHandler()

  // Most configuration of queryClient is done in @nrcstat-monorepo/react-query/query,
  // this is the final config. It delegates all react-query exceptions to be channeled
  // to the react-error-boundary error handler, ensuring that our <RootLevelErrorBoundary>
  // will catch them.
  const queryClientInner = useQueryClient()
  useEffect(() => {
    queryClientInner.setDefaultOptions({
      queries: {
        onError: (err) => {
          handleError(err)
        },
      },
      mutations: {
        onError: (err) => {
          handleError(err)
        },
      },
    })
  }, [handleError, queryClientInner])

  const [navState, navStateSend] = useAppNavigationService()
  const isFetching = useIsFetching()
  const isMutating = useIsMutating()
  const isBusy = Boolean(isFetching) || Boolean(isMutating)

  const { data: isLoggedIn, isSuccess: hasDeterminedLoggedIn } = useIsLoggedIn()

  useEffect(() => {
    if (hasDeterminedLoggedIn) {
      if (isLoggedIn) {
        navStateSend('LOGIN_SUCCESS')
      } else {
        navStateSend('LOGOUT')
      }
    }
  }, [isLoggedIn, hasDeterminedLoggedIn, navStateSend])

  // called to set up i18n
  useI18n()

  return (
    <>
      {navState.matches('loggedIn.main') ? (
        <Main />
      ) : navState.matches('loggedIn.buildChart') ? (
        <BuildChart />
      ) : navState.matches('loggedIn.buildPictogram') ? (
        <BuildPictogram />
      ) : navState.matches('loggedIn.buildTimeline') ? (
        <BuildTimeline />
      ) : navState.matches('loggedIn.prepareCountrySidebarId') ? (
        <PrepareCountrySidebarId />
      ) : navState.matches('loggedOut.loginForm') ? (
        <LoginForm />
      ) : null}
      <Backdrop open={isBusy} style={{ zIndex: 9999 }}>
        <CircularProgress />
      </Backdrop>
    </>
  )
}

export default App
