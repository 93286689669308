import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { ChartWidget } from '@nrcstat-monorepo/config-and-definitions'
import { useI18n } from '@nrcstat-monorepo/i18n'
import {
  usePostWidgetMutation,
  usePutWidget,
} from '@nrcstat-monorepo/react-query/query'
import {
  useAppNavigationService,
  useChartBuilderService,
} from '@nrcstat-monorepo/widget-builder/machines'
import {
  buildShareableHtmlPage,
  uploadWidgetHtmlFileToS3Bucket,
  useMutationUploadWidgetThumbnailToCloudinary,
  useRenderWidgetThumbnailBlob,
} from '@nrcstat-monorepo/widget-social-media-sharing'
import React, { useCallback, useRef } from 'react'
import ReactMarkdown from 'react-markdown'

interface Props {
  widgetContainerElementRef: React.MutableRefObject<
    HTMLDivElement | null | undefined
  >
}

export function SaveChartModal(props: Props) {
  const [navState, navStateSend] = useAppNavigationService()
  const [, chartBuilderSend] = useChartBuilderService()

  const isOpen =
    navState.matches('loggedIn.buildChart.saveModal.specifyInternalName') ||
    navState.matches('loggedIn.buildChart.saveModal.widgetSavedConfirmation')

  const handleClickOutsideModal = () => {
    if (navState.matches('loggedIn.buildChart.saveModal.specifyInternalName')) {
      navStateSend('CLOSE_SAVE_MODAL')
    } else if (
      navState.matches('loggedIn.buildChart.saveModal.widgetSavedConfirmation')
    ) {
      navStateSend('CANCEL')
      chartBuilderSend('RESET')
    }
  }

  return (
    <Dialog open={isOpen} onClose={handleClickOutsideModal}>
      {navState.matches('loggedIn.buildChart.saveModal.specifyInternalName') ? (
        <SpecifyInternalName {...props} />
      ) : null}
      {navState.matches(
        'loggedIn.buildChart.saveModal.widgetSavedConfirmation'
      ) ? (
        <WidgetSaveConfirmation />
      ) : null}
    </Dialog>
  )
}

function SpecifyInternalName({ widgetContainerElementRef }: Props) {
  const [, navStateSend] = useAppNavigationService()
  const [chartBuilder, chartBuilderSend] = useChartBuilderService()
  const postWidget = usePostWidgetMutation<ChartWidget>()
  const putWidget = usePutWidget<ChartWidget>()
  const renderWidgetThumbnailBlob = useRenderWidgetThumbnailBlob()
  const uploadWidgetHtmlFileToS3 = uploadWidgetHtmlFileToS3Bucket()
  const uploadWidgetThumbnailToCloudinary = useMutationUploadWidgetThumbnailToCloudinary()

  const chartWidget = chartBuilder.context.widget

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    chartBuilderSend({
      type: 'CHANGE_INTERNAL_NAME',
      internalName: event.target.value,
    })
  }

  const onWidgetSave = async () => {
    const savedWidgetObject = await postWidget.mutateAsync(chartWidget)

    // We expect that the widget has already been fully rendered into #widget-container
    // as done by the <BuildPictogram> function. We further expect that inside of that
    // element there is a div element with the class "container" .
    // These dependencies should maybe be made more explicit and formalized into code?
    const thumbnailImageDataUrl = await renderWidgetThumbnailBlob.mutateAsync(
      widgetContainerElementRef
    )

    if (!thumbnailImageDataUrl) return

    const cloudinaryUploadResponse = await (
      await uploadWidgetThumbnailToCloudinary
    ).mutateAsync(thumbnailImageDataUrl)

    await putWidget.mutateAsync({
      widgetId: savedWidgetObject.id as string,
      updates: { cloudinaryThumbnailUrl: cloudinaryUploadResponse.secure_url },
    })

    const shareableHtmlPage = buildShareableHtmlPage(
      savedWidgetObject,
      chartBuilder.context.activeLocale,
      cloudinaryUploadResponse
    )

    await uploadWidgetHtmlFileToS3(
      savedWidgetObject.id as string,
      shareableHtmlPage
    )

    chartBuilderSend('REPLACE_WIDGET', { widget: savedWidgetObject })
    navStateSend('SAVE_SUCCESS')
  }

  return (
    <>
      <DialogTitle>Name your visualisation</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Give your widget a name so it can be found easily afterwards. This
          name will not be visible in the visualization on the website.
        </Typography>
        <Grid container>
          <TextField
            fullWidth
            margin="normal"
            label="Visualisation title"
            variant="outlined"
            onChange={onInputChange}
            value={chartBuilder.context.widget.internalName}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => navStateSend('CLOSE_SAVE_MODAL')}>Cancel</Button>
        <Button onClick={onWidgetSave} variant="contained" color="primary">
          Create chart
        </Button>
      </DialogActions>
    </>
  )
}

function WidgetSaveConfirmation() {
  const [, navStateSend] = useAppNavigationService()
  const [chartBuilder, chartBuilderSend] = useChartBuilderService()
  const successfullySavedChartWidget = chartBuilder.context.widget

  const inputElementRef = useRef<HTMLInputElement>()

  const onCopyClick = () => {
    inputElementRef.current?.select()
    document.execCommand('copy')
  }

  const t = useI18n()

  return (
    <>
      <DialogTitle>Successfully created!</DialogTitle>
      <DialogContent>
        <Stack direction="row" alignItems="center" spacing="20px">
          <span>Visualisation ID</span>:{' '}
          <TextField
            InputLabelProps={{ shrink: false }}
            value={successfullySavedChartWidget.id}
            style={{ width: '300px' }}
            size="small"
            inputRef={inputElementRef}
          />
          <IconButton onClick={onCopyClick} size="large">
            <ContentCopyIcon fontSize="small" />
          </IconButton>
          {/* <Card>
        <CardContent>
          
        </CardContent>
      </Card> */}
        </Stack>
        <Paper square elevation={3} sx={{ p: 2, mt: 4, bgcolor: 'lightgrey' }}>
          <Typography variant="h6">
            HOW TO ADD VISUALISATION TO THE WEBSITE
          </Typography>
          <ReactMarkdown
            children={t(
              `VisualisationBuilder.UserInterfaceText:visualisationSavedConfirmationDialog.howToAddToWebsiteInstructions`
            )}
          />
        </Paper>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button
          variant="contained"
          onClick={() => {
            navStateSend('CANCEL')
            chartBuilderSend('RESET')
          }}
        >
          Close
        </Button>
      </DialogActions>
    </>
  )
}
