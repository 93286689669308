import { HotTable } from '@handsontable/react'
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import {
  ChartWidget,
  CustomDataColumnSettings,
  CustomDataColumnSettingsLocaleLess,
  CustomDataRowObjectLocaleLess,
  LocizeLocale,
  LOCIZE_ALL_LOCALES,
} from '@nrcstat-monorepo/config-and-definitions'
import { exhaustiveCheck } from '@nrcstat-monorepo/utils'
import {
  addColumnsLocaleLayer,
  removeCustomDataLocaleLayer,
  useChartBuilderService,
} from '@nrcstat-monorepo/widget-builder/machines'
import Handsontable from 'handsontable/base'
import 'handsontable/dist/handsontable.full.css'
import { registerAllModules } from 'handsontable/registry'
import produce from 'immer'
import { get, set } from 'lodash'
import { useEffect, useReducer, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'

import arrowToColumn from './arrow-to-column.png'

registerAllModules()

export function CustomData() {
  const [chartBuilder, chartBuilderSend] = useChartBuilderService()
  const {
    widget: {
      customData: { columns, data },
    },
    activeLocale,
  } = chartBuilder.context

  const [hotSettings, dataTableDispatch] = useReducer(
    reducer,
    initialHotSettings(
      ...removeCustomDataLocaleLayer(activeLocale, columns, data)
    )
  )
  useEffect(
    function copyToBuilderOnWrite() {
      const hotColumns = hotSettings.columns as CustomDataColumnSettingsLocaleLess[]
      const hotData = hotSettings.data as CustomDataRowObjectLocaleLess[]
      chartBuilderSend('UPDATE_LOCALELESS_CUSTOM_DATA', {
        columns: hotColumns,
        data: hotData,
      })
    },
    [chartBuilderSend, hotSettings.columns, hotSettings.data]
  )
  const hookDispatcher = (action: ACTIONS) => {
    dataTableDispatch(action)
    return false
  }
  const hotTableRef = useRef<HotTable>(null)

  const onTabChange = (_: unknown, newLocale: LocizeLocale) => {
    chartBuilderSend({ type: 'CHANGE_ACTIVE_LOCALE', newLocale })
    const [hotColumns, hotData] = removeCustomDataLocaleLayer(
      newLocale,
      columns,
      data
    )
    dataTableDispatch({
      type: 'SET_COLUMNS_AND_DATA',
      payload: { columns: hotColumns, data: hotData },
    })
  }

  return (
    <>
      <Tabs value={activeLocale} onChange={onTabChange}>
        {LOCIZE_ALL_LOCALES.map((locale) => (
          <Tab key={locale} label={locale.toUpperCase()} value={locale} />
        ))}
      </Tabs>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Here you can enter custom data that will be used in your
            visualisation. It is possible to paste data from your spreadsheet.
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            Right-click on cells to add, remove or rename columns.
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            <Box
              sx={{
                position: 'relative',
                display: 'inline',
                top: '15px',
                ml: 3,
              }}
            >
              <img src={arrowToColumn} alt="" />
            </Box>{' '}
            First column should contain labels (e.g. year, country, group...)
          </Typography>
          <HotTable
            settings={{
              ...hotSettings,
              contextMenu: {
                items: {
                  rename: {
                    name: 'Rename column',
                    disabled: () => {
                      const selection = hotTableRef.current?.hotInstance?.getSelectedLast()
                      if (!selection) return true
                      const [startRow, startCol, endRow, endCol] = selection
                      // Disable option if multiple columns are selected
                      if (startCol !== endCol) return true
                      return false
                    },
                    callback: (
                      _key: string,
                      selection: Handsontable.plugins.ContextMenu.Selection[]
                    ) => {
                      const selectedCol = selection[0].start.col
                      const newLabel =
                        prompt(
                          'Enter new column name',
                          hotSettings.columns[selectedCol].columnLabel
                        ) || ''
                      dataTableDispatch({
                        type: 'CHANGE_COLUMN_LABEL',
                        payload: { index: selectedCol, label: newLabel },
                      })
                    },
                  },

                  col_left: {
                    disabled: () => {
                      const selection = hotTableRef.current?.hotInstance?.getSelectedLast()
                      if (!selection) return true
                      const [startRow, startCol, endRow, endCol] = selection
                      if (startCol === 0) return true
                      return false
                    },
                    callback: (
                      _key: string,
                      selection: Handsontable.plugins.ContextMenu.Selection[]
                    ) => {
                      const insertNewColAfterIndex = selection[0].start.col
                      hookDispatcher({
                        type: 'ADD_COLUMN',
                        payload: {
                          index: insertNewColAfterIndex,
                          columnKey: uuidv4(),
                        },
                      })
                    },
                  },
                  col_right: {
                    disabled: () => false,
                    callback: (
                      _key: string,
                      selection: Handsontable.plugins.ContextMenu.Selection[]
                    ) => {
                      const insertNewColAfterIndex = selection[0].end.col + 1
                      hookDispatcher({
                        type: 'ADD_COLUMN',
                        payload: {
                          index: insertNewColAfterIndex,
                          columnKey: uuidv4(),
                        },
                      })
                    },
                  },
                  remove_col: {
                    disabled: () => {
                      const selection = hotTableRef.current?.hotInstance?.getSelectedLast()
                      if (!selection) return true
                      const [startRow, startCol, endRow, endCol] = selection
                      if (startCol === 0) return true
                      return false
                    },
                    callback: (
                      _key: string,
                      selection: Handsontable.plugins.ContextMenu.Selection[]
                    ) => {
                      const {
                        start: { col: startCol },
                        end: { col: endCol },
                      } = selection[0]
                      const seletedMultipleCols = startCol !== endCol
                      if (seletedMultipleCols) return
                      hookDispatcher({
                        type: 'REMOVE_COLUMN',
                        payload: {
                          index: startCol,
                        },
                      })
                    },
                  },
                  separator: { name: '---------' },
                  row_above: {},
                  row_below: {},
                  remove_row: {},
                },
              },
            }}
            ref={hotTableRef}
            beforeChange={(changes: Handsontable.CellChange[]) =>
              hookDispatcher({ type: 'SET_CELL_VALUES', payload: { changes } })
            }
            beforeCreateRow={(index: number, amount: number) =>
              hookDispatcher({ type: 'ADD_ROW', payload: { index, amount } })
            }
            beforeRemoveRow={(index: number, amount: number) =>
              hookDispatcher({
                type: 'REMOVE_ROWS',
                payload: { index, amount },
              })
            }
            beforeKeyDown={(event: KeyboardEvent) => {
              if (event.key === 'Enter') {
                const currentCell = hotTableRef.current?.hotInstance?.getSelected()
                const currentRowCount = hotTableRef.current?.hotInstance?.countRows()
                if (!currentCell) return
                if (currentRowCount === undefined) return
                const [startRow, startCol, endRow, endCol] = currentCell[0]
                const isSingleCell = startRow === endRow && startCol === endCol
                if (!isSingleCell) return
                const isLastRow = endRow === currentRowCount - 1
                if (!isLastRow) return
                dataTableDispatch({
                  type: 'ADD_ROW',
                  payload: { index: endRow + 1, amount: 1 },
                })
                // event.stopImmediatePropagation()
              }
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Card>
            <CardContent>
              <Typography variant="body1" sx={{ mb: 2 }} fontWeight="bold">
                Which widgets will this data set work with?
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Below you can find all chart types available and sample of data
                structure suitable for it.
              </Typography>
              <Typography variant="body2">
                {determineIsWidgetCompatibleWithColumns(columns, 'donut')
                  ? '✅'
                  : '❌'}{' '}
                Bar{' '}
                <ExampleDataButton
                  hookDispatcher={hookDispatcher}
                  columns={barColumnDonutExampleData.columns}
                  data={barColumnDonutExampleData.data}
                  locale={activeLocale}
                />
              </Typography>
              <Typography variant="body2">
                {determineIsWidgetCompatibleWithColumns(columns, 'column')
                  ? '✅'
                  : '❌'}{' '}
                Column{' '}
                <ExampleDataButton
                  hookDispatcher={hookDispatcher}
                  columns={barColumnDonutExampleData.columns}
                  data={barColumnDonutExampleData.data}
                  locale={activeLocale}
                />
              </Typography>
              <Typography variant="body2">
                {determineIsWidgetCompatibleWithColumns(columns, 'donut')
                  ? '✅'
                  : '❌'}{' '}
                Donut{' '}
                <ExampleDataButton
                  hookDispatcher={hookDispatcher}
                  columns={barColumnDonutExampleData.columns}
                  data={barColumnDonutExampleData.data}
                  locale={activeLocale}
                />
              </Typography>
              <Typography variant="body2">
                {determineIsWidgetCompatibleWithColumns(columns, 'line')
                  ? '✅'
                  : '❌'}{' '}
                Line{' '}
                <ExampleDataButton
                  hookDispatcher={hookDispatcher}
                  columns={lineExampleData.columns}
                  data={lineExampleData.data}
                  locale={activeLocale}
                />
              </Typography>
              <Typography variant="body2">
                ✅ Table
                <ExampleDataButton
                  hookDispatcher={hookDispatcher}
                  columns={lineExampleData.columns}
                  data={lineExampleData.data}
                  locale={activeLocale}
                />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

type NarrowedGridSettings = Handsontable.GridSettings & {
  columns: (Handsontable.ColumnSettings & { columnLabel: string })[]
}

type ACTIONS =
  | { type: 'ADD_COLUMN'; payload: { index: number; columnKey: string } }
  | { type: 'REMOVE_COLUMN'; payload: { index: number } }
  | { type: 'CHANGE_COLUMN_LABEL'; payload: { index: number; label: string } }
  | { type: 'ADD_ROW'; payload: { index: number; amount: number } }
  | { type: 'REMOVE_ROWS'; payload: { index: number; amount: number } }
  | { type: 'SET_CELL_VALUES'; payload: { changes: Handsontable.CellChange[] } }
  | {
      type: 'SET_COLUMNS_AND_DATA'
      payload: {
        columns: CustomDataColumnSettingsLocaleLess[]
        data: CustomDataRowObjectLocaleLess[]
      }
    }

function reducer(
  state: NarrowedGridSettings,
  action: ACTIONS
): NarrowedGridSettings {
  const newState = (() => {
    console.log(action.type)
    switch (action.type) {
      case 'ADD_COLUMN':
        return produce(state, (draft) => {
          draft.columns?.splice(action.payload.index, 0, {
            type: 'numeric',
            data: action.payload.columnKey,
            columnLabel: 'New column',
          })
        })
      case 'REMOVE_COLUMN':
        return produce(state, (draft) => {
          draft.columns?.splice(action.payload.index, 1)
        })
      case 'CHANGE_COLUMN_LABEL':
        return produce(state, (draft) => {
          draft.columns[action.payload.index].columnLabel = action.payload.label
        })
      case 'SET_CELL_VALUES':
        // eslint-disable-next-line no-case-declarations
        const newstuff = produce(state, (draft) => {
          action.payload.changes.forEach(
            ([row, column, oldValue, newValue]) => {
              // TODO: I feel this is a bit hacky and circumvents TS. I should rather do something like
              // data[row][column] = newValue but with some checks and/or narrowing. But... I spent an hour
              // trying to figure it out, so I'll take the easy route and let lodash's `set` do the heavy lifting.

              if (column !== 'label') {
                newValue = Number(newValue)
                if (isNaN(newValue)) return
              }
              set(draft, `data[${row}].${column}`, newValue)
              if (!get(draft, `data[${row}].id`)) {
                console.log('allaho', column)
                set(draft, `data[${row}].id`, uuidv4())
              }
            }
          )
        })
        console.log(newstuff)
        return newstuff
      case 'SET_COLUMNS_AND_DATA':
        return produce(state, (draft) => {
          draft.columns = action.payload.columns
          draft.data = action.payload.data
        })
      case 'ADD_ROW':
        return produce(state, (draft) => {
          ;(draft.data as any).splice(action.payload.index, 0, { id: uuidv4() })
        })
      case 'REMOVE_ROWS':
        return produce(state, (draft) => {
          ;(draft.data as any).splice(
            action.payload.index,
            action.payload.amount
          )
        })
      default:
        return state
    }
  })()
  return hotSettingsComputeColHeaders(newState)
}
function hotSettingsComputeColHeaders(
  settings: NarrowedGridSettings
): NarrowedGridSettings {
  return {
    ...settings,
    colHeaders: settings.columns.map(({ columnLabel }) => columnLabel),
  }
}
const initialHotSettings: (
  columns: CustomDataColumnSettingsLocaleLess[],
  data: CustomDataRowObjectLocaleLess[]
) => NarrowedGridSettings = (columns, data) =>
  hotSettingsComputeColHeaders({
    columns,
    data,
    rowHeaders: false,
    height: 'auto',
    licenseKey: 'non-commercial-and-evaluation',
  })

export function determineIsWidgetCompatibleWithColumns(
  columns: CustomDataColumnSettings[],
  widgetType: ChartWidget['type']
) {
  switch (widgetType) {
    case 'bar':
    case 'column':
    case 'donut':
      return columns.length === 2

    case 'line':
    case 'table':
      return columns.length >= 2

    default:
      return exhaustiveCheck(widgetType)
  }
}

interface ExampleDataButtonProps {
  locale: LocizeLocale
  columns: ChartWidget['customData']['columns']
  data: ChartWidget['customData']['data']
  hookDispatcher: (action: ACTIONS) => void
}

function ExampleDataButton({
  locale,
  columns,
  data,
  hookDispatcher,
}: ExampleDataButtonProps) {
  const [, chartBuilderSend] = useChartBuilderService()

  return (
    <Button
      onClick={() => {
        if (window.confirm('Overwite existing data with example data?')) {
          chartBuilderSend({
            type: 'UPDATE_CUSTOM_DATA',
            columns: columns,
            data: data,
          })
          const [hotColumns, hotData] = removeCustomDataLocaleLayer(
            locale,
            columns,
            data
          )
          hookDispatcher({
            type: 'SET_COLUMNS_AND_DATA',
            payload: { columns: hotColumns, data: hotData },
          })
        }
      }}
    >
      insert example data
    </Button>
  )
}

const lineExampleData = {
  columns: [
    {
      type: 'text',
      data: 'label',
      columnLabel: {
        'en-GB': 'Year',
        'nb-NO': 'År',
        'de-DE': 'Jahr',
        'sv-SE': 'År',
      },
    },
    {
      renderer: 'numeric',
      data: 'data_someNumber',
      columnLabel: {
        'en-GB': 'Internally displaced (millions)',
        'nb-NO': 'Internt fordrevne (millioner)',
        'de-DE': 'Binnenvertriebene (Millionen)',
        'sv-SE': 'Internt fördrivna (miljoner)',
      },
    },
    {
      renderer: 'numeric',
      data: 'data_someNumber2',
      columnLabel: {
        'en-GB': 'Refugees (millions)',
        'nb-NO': 'Flyktninger (millioner)',
        'de-DE': 'Flüchtlinge (Millionen)',
        'sv-SE': 'Refugees (millions)',
      },
    },
  ],
  data: [
    {
      id: '111',
      label: {
        'en-GB': '2016',
        'nb-NO': '2016',
        'de-DE': '2016',
        'sv-SE': '2016',
      },
      data_someNumber: 40,
      data_someNumber2: 25,
    },
    {
      id: '222',
      label: {
        'en-GB': '2017',
        'nb-NO': '2017',
        'de-DE': '2017',
        'sv-SE': '2017',
      },
      data_someNumber: 40,
      data_someNumber2: 29,
    },
    {
      id: '333',
      label: {
        'en-GB': '2018',
        'nb-NO': '2018',
        'de-DE': '2018',
        'sv-SE': '2018',
      },
      data_someNumber: 41,
      data_someNumber2: 31,
    },
    {
      id: '444',
      label: {
        'en-GB': '2019',
        'nb-NO': '2019',
        'de-DE': '2019',
        'sv-SE': '2019',
      },
      data_someNumber: 46,
      data_someNumber2: 34,
    },
    {
      id: '555',
      label: {
        'en-GB': '2020',
        'nb-NO': '2020',
        'de-DE': '2020',
        'sv-SE': '2020',
      },
      data_someNumber: 48,
      data_someNumber2: 34,
    },
  ],
}

const barColumnDonutExampleData = {
  columns: [
    {
      type: 'text',
      data: 'label',
      columnLabel: {
        'en-GB': 'Region',
        'nb-NO': 'Region',
        'de-DE': 'Region',
        'sv-SE': 'Område',
      },
    },
    {
      renderer: 'numeric',
      data: 'data_someNumber',
      columnLabel: {
        'en-GB': 'Internally displaced (millions)',
        'nb-NO': 'Internt fordrevne (millioner)',
        'de-DE': 'Binnenvertriebene (Millionen)',
        'sv-SE': 'Internt fördrivna (miljoner)',
      },
    },
  ],
  data: [
    {
      id: '111',
      label: {
        'en-GB': 'Middle East',
        'nb-NO': 'Midtøsten',
        'de-DE': 'Naher Osten',
        'sv-SE': 'Mellanöstern',
      },
      data_someNumber: 12,
    },
    {
      id: '222',
      label: {
        'en-GB': 'Americas',
        'nb-NO': 'Amerika',
        'de-DE': 'Amerika',
        'sv-SE': 'Amerika',
      },
      data_someNumber: 6,
    },
    {
      id: '333',
      label: {
        'en-GB': 'Europe',
        'nb-NO': 'Europa',
        'de-DE': 'Europa',
        'sv-SE': 'Europa',
      },
      data_someNumber: 3,
    },
    {
      id: '333',
      label: {
        'en-GB': 'Africa',
        'nb-NO': 'Afrika',
        'de-DE': 'Afrika',
        'sv-SE': 'Afrika',
      },
      data_someNumber: 22,
    },
    {
      id: '444',
      label: {
        'en-GB': 'Asia and Oceania',
        'nb-NO': 'Asia og Oseania',
        'de-DE': 'Asien und Ozeanien',
        'sv-SE': 'Asien och Oceanien',
      },
      data_someNumber: 5,
    },
  ],
}
