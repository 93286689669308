export const WidgetType = {
  timeline: 'timeline',
  pictogram: 'pictogram',
  donut: 'donut',
  line: 'line',
  bar: 'bar',
  column: 'column',
  table: 'table',
} as const
export type WidgetType = typeof WidgetType[keyof typeof WidgetType]
