import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import SearchIcon from '@mui/icons-material/Search'
import {
  IconButton,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material'
import { styled } from '@mui/styles'
import { WidgetBase } from '@nrcstat-monorepo/config-and-definitions'
import { useFetchWidgets } from '@nrcstat-monorepo/react-query/query'
import { useAppNavigationService } from '@nrcstat-monorepo/widget-builder/machines'
import React, { ChangeEvent, useRef, useState } from 'react'
import { useDebounce } from '../../hooks/use-debounce'

export function SavedWidgetsTable() {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [query, setQuery] = useState('')
  const queryDebounced = useDebounce(query, 700)

  const { data: widgets } = useFetchWidgets(queryDebounced)

  const paginatedWidgets = widgets?.slice(
    page * rowsPerPage,
    (page + 1) * rowsPerPage
  )

  return (
    <>
      <TableContainer component={Paper}>
        <Toolbar
          sx={{
            backgroundColor: '#000',
            color: '#fff',
            mb: 1,
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="body1">Latest visualisations</Typography>
          <Paper>
            <TextField
              placeholder="Search"
              margin="normal"
              value={query}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setQuery(event.target.value)
              }
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
              sx={{ margin: 0, padding: 0 }}
            />
          </Paper>
        </Toolbar>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledHeaderCell>Visualisation ID</StyledHeaderCell>
              <StyledHeaderCell>Internal name</StyledHeaderCell>
              <StyledHeaderCell>Type</StyledHeaderCell>
              <StyledHeaderCell>Creator</StyledHeaderCell>
              <StyledHeaderCell>Created date</StyledHeaderCell>
              <StyledHeaderCell></StyledHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedWidgets?.map((widget) => (
              <WidgetsTableRow key={widget.id} widget={widget} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={widgets?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(_: unknown, newPage: number) => setPage(newPage)}
        onRowsPerPageChange={(event: ChangeEvent<HTMLInputElement>) =>
          setRowsPerPage(parseInt(event.target.value))
        }
      />
    </>
  )
}

const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.root}`]: {
    fontSize: '1rem',
    fontWeight: 'bold',
  },
}))

function WidgetsTableRow({ widget }: { widget: WidgetBase }) {
  const [, navStateSend] = useAppNavigationService()

  const widgetIdInputRef = useRef<HTMLInputElement>(null)
  const onCopyClick = () => {
    widgetIdInputRef.current?.select()
    document.execCommand('copy')
  }

  return (
    <TableRow key={widget.id}>
      <TableCell>
        <div style={{ minWidth: '235px' }}>
          <InputBase
            value={widget.id}
            sx={{
              width: '190px',
              '& .MuiInputBase-input': { fontSize: '0.875rem' },
            }}
            inputRef={widgetIdInputRef}
          />
          <IconButton onClick={onCopyClick} size="large">
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </div>
      </TableCell>
      <TableCell>{widget.internalName}</TableCell>
      <TableCell>{widget.type}</TableCell>
      <TableCell>{widget.createdBy}</TableCell>
      <TableCell>
        {new Intl.DateTimeFormat('nb-NO').format(
          new Date(String(widget.dateCreated))
        )}
      </TableCell>
      <TableCell>
        <IconButton
          onClick={() =>
            navStateSend({
              type: 'OPEN_WIDGET_MODAL',
              widgetId: widget.id as string,
            })
          }
          size="large"
        >
          <OpenInNewIcon fontSize="small" />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}
