import { useCurrentUser, useIsLoggedIn } from '@nrcstat-monorepo/auth'
import * as React from 'react'

export function RenderIfUserAdmin({ children }: { children: React.ReactNode }) {
  const { data: isLoggedIn } = useIsLoggedIn()
  const { data: currentUser } = useCurrentUser()

  const userIsAdmin =
    isLoggedIn &&
    [
      'silje.lutnas@nrc.no',
      'lars.torjesen@nrc.no',
      'eric@binarylights.com',
    ].includes(`${currentUser?.email}`)

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return userIsAdmin ? <>{children}</> : null
}
