import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { TimelineWidget } from '@nrcstat-monorepo/config-and-definitions'
import {
  usePostWidgetMutation,
  usePutWidget,
} from '@nrcstat-monorepo/react-query/query'
import {
  useAppNavigationService,
  useTimelineBuilderService,
} from '@nrcstat-monorepo/widget-builder/machines'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import React, { useCallback, useRef } from 'react'
import ReactMarkdown from 'react-markdown'
import { useI18n } from '@nrcstat-monorepo/i18n'
import {
  useMutationUploadWidgetThumbnailToCloudinary,
  useRenderWidgetThumbnailBlob,
} from '@nrcstat-monorepo/widget-social-media-sharing'

interface Props {
  widgetContainerElementRef: React.MutableRefObject<
    HTMLDivElement | null | undefined
  >
}

export function SaveTimelineModal(props: Props) {
  const [navState, navStateSend] = useAppNavigationService()

  const isOpen =
    navState.matches('loggedIn.buildTimeline.saveModal.specifyInternalName') ||
    navState.matches('loggedIn.buildTimeline.saveModal.widgetSavedConfirmation')

  const handleClose = useCallback(() => {
    navStateSend('CLOSE_SAVE_MODAL')
  }, [navStateSend])

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      {navState.matches(
        'loggedIn.buildTimeline.saveModal.specifyInternalName'
      ) ? (
        <SpecifyInternalName {...props} />
      ) : null}
      {navState.matches(
        'loggedIn.buildTimeline.saveModal.widgetSavedConfirmation'
      ) ? (
        <WidgetSaveConfirmation />
      ) : null}
    </Dialog>
  )
}

function SpecifyInternalName({ widgetContainerElementRef }: Props) {
  const [, navStateSend] = useAppNavigationService()
  const [timelineBuilder, timelineBuilderSend] = useTimelineBuilderService()
  const postWidget = usePostWidgetMutation<TimelineWidget>()
  const putWidget = usePutWidget<TimelineWidget>()
  const renderWidgetThumbnailBlob = useRenderWidgetThumbnailBlob()
  const uploadWidgetThumbnailToCloudinary = useMutationUploadWidgetThumbnailToCloudinary()

  const timelineWidget = timelineBuilder.context.widget

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      timelineBuilderSend({
        type: 'CHANGE_INTERNAL_NAME',
        internalName: event.target.value,
      })
    },
    [timelineBuilderSend]
  )

  const onWidgetSave = async () => {
    const savedWidgetObject = await postWidget.mutateAsync(timelineWidget)

    // We expect that the widget has already been fully rendered into #widget-container
    // as done by the <BuildPictogram> function. We further expect that inside of that
    // element there is a div element with the class "container" .
    // These dependencies should maybe be made more explicit and formalized into code?
    const thumbnailImageDataUrl = await renderWidgetThumbnailBlob.mutateAsync(
      widgetContainerElementRef
    )

    if (!thumbnailImageDataUrl) return

    const cloudinaryUploadResponse = await (
      await uploadWidgetThumbnailToCloudinary
    ).mutateAsync(thumbnailImageDataUrl)

    const updatedWidgetObject = await putWidget.mutateAsync({
      widgetId: savedWidgetObject.id as string,
      updates: {
        cloudinaryThumbnailUrl: cloudinaryUploadResponse.secure_url,
      },
    })

    timelineBuilderSend({
      type: 'REPLACE_WIDGET',
      widget: updatedWidgetObject,
    })
    navStateSend('SAVE_SUCCESS')
  }

  // const onWidgetSave = useCallback(
  //   () =>
  //     mutation.mutate(timelineWidget, {
  //       onSuccess: (timelineWidget) => {
  //         timelineBuilderSend({
  //           type: 'REPLACE_WIDGET',
  //           widget: timelineWidget,
  //         })
  //         navStateSend('SAVE_SUCCESS')
  //       },
  //     }),
  //   [mutation, timelineWidget, timelineBuilderSend, navStateSend]
  // )

  return (
    <>
      <DialogTitle>Name your visualisation</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Give your widget a name so it can be found easily afterwards. This
          name will not be visible in the visualization on the website.
        </Typography>
        <Grid container>
          <TextField
            fullWidth
            margin="normal"
            label="Visualisation title"
            variant="outlined"
            onChange={onInputChange}
            value={timelineBuilder.context.widget.internalName}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => navStateSend('CLOSE_SAVE_MODAL')}>Cancel</Button>
        <Button onClick={onWidgetSave} variant="contained" color="primary">
          Create timeline
        </Button>
      </DialogActions>
    </>
  )
}

function WidgetSaveConfirmation() {
  const [, navStateSend] = useAppNavigationService()
  const [timelineBuilder, timelineBuilderSend] = useTimelineBuilderService()
  const successfullySavedTimelineWidget = timelineBuilder.context.widget

  const inputElementRef = useRef<HTMLInputElement>()

  const onCopyClick = useCallback(() => {
    inputElementRef.current?.select()
    document.execCommand('copy')
  }, [])

  const t = useI18n()

  return (
    <>
      <DialogTitle>Successfully created!</DialogTitle>
      <DialogContent>
        <Stack direction="row" alignItems="center" spacing="20px">
          <span>Visualisation ID</span>:{' '}
          <TextField
            InputLabelProps={{ shrink: false }}
            value={successfullySavedTimelineWidget.id}
            style={{ width: '300px' }}
            size="small"
            inputRef={inputElementRef}
          />
          <IconButton onClick={onCopyClick} size="large">
            <ContentCopyIcon fontSize="small" />
          </IconButton>
          {/* <Card>
        <CardContent>
          
        </CardContent>
      </Card> */}
        </Stack>
        <Paper square elevation={3} sx={{ p: 2, mt: 4, bgcolor: 'lightgrey' }}>
          <Typography variant="h6">
            HOW TO ADD VISUALISATION TO THE WEBSITE
          </Typography>
          <ReactMarkdown
            children={t(
              `VisualisationBuilder.UserInterfaceText:visualisationSavedConfirmationDialog.howToAddToWebsiteInstructions`
            )}
          />
        </Paper>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button
          variant="contained"
          onClick={() => {
            navStateSend('CANCEL')
            timelineBuilderSend('RESET')
          }}
        >
          Close
        </Button>
      </DialogActions>
    </>
  )
}
