import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import {
  LocizeLocale,
  LOCIZE_ALL_LOCALES,
} from '@nrcstat-monorepo/config-and-definitions'
import { Layout, TabPanel } from '@nrcstat-monorepo/widget-builder/components'
import {
  useAppNavigationService,
  usePictogramBuilderService,
} from '@nrcstat-monorepo/widget-builder/machines'
import React, { useCallback, useRef } from 'react'
import { useWidgetPreview } from '../../hooks/use-widget-preview'
import { DefineSections } from './DefineSections'
import { DescribePictogram } from './DescribePictogram'
import { SavePictogramModal } from './SavePictogramModal'

export function BuildPictogram() {
  const [navState, navStateSend] = useAppNavigationService()

  const [pictogramBuilder, pictogramBuilderSend] = usePictogramBuilderService()

  const { widget, activeLocale } = pictogramBuilder.context

  useWidgetPreview('#widget-container', widget, activeLocale)

  const currentTab = activeLocale

  const onTabChange = useCallback(
    (_, newTab: LocizeLocale) => {
      pictogramBuilderSend({ type: 'CHANGE_ACTIVE_LOCALE', newLocale: newTab })
    },
    [pictogramBuilderSend]
  )

  // Temporary. TODO: Remove me
  const onDummyClick = () => {
    pictogramBuilderSend({
      type: 'REPLACE_WIDGET',
      widget: {
        internalName: '',
        title: { 'nb-NO': 'Education gender inequality in Yemen' },
        subtitle: { 'nb-NO': 'Ongoing crisis further increases the gap.' },
        source: { 'nb-NO': 'Source: UNCHR, May 2020' },
        socialMediaDescription: {
          'nb-NO':
            'Some description that will appear on Facebook and LinkedIn when this widget is shared',
        },
        backgroundColor: 'white',
        type: 'pictogram',
        sections: [
          {
            uniqKey: 'e37632c9-96a0-448c-87fb-bd6c2b4f004b',
            title: { 'nb-NO': 'Section 1' },
            icons: [
              {
                uniqKey: 'ff5c48fd-49c1-4f6c-9a60-51ac8ad78629',
                icon: 'Camp',
                data: 0.7,
                dataColor: '#FD5A00',
              },
              {
                uniqKey: '6a2563a5-b5fb-46a5-98cf-e6666a568716',
                icon: 'Education',
                data: 0.8,
                dataColor: '#007D99',
              },
            ],
          },
          {
            uniqKey: '511bf4a7-f332-487a-a080-9127c1d382fa',
            title: { 'nb-NO': 'Section 2' },
            icons: [
              {
                uniqKey: '23c82511-1e04-4557-b161-519785918e10',
                icon: 'Food',
                data: 0.85,
                dataColor: '#FDC82F',
              },
              {
                uniqKey: 'b4378364-9cb1-48b1-8646-95367d4fc439',
                icon: 'Shelter',
                data: 0.4,
                dataColor: '#FF2B00',
              },
            ],
          },
        ],
        isTemplate: false,
      },
    })
    pictogramBuilderSend({ type: 'CHANGE_ACTIVE_LOCALE', newLocale: 'nb-NO' })
  }

  const onResetWidgetClick = () =>
    window.confirm('Are you sure?') && pictogramBuilderSend('RESET')

  const widgetContainerElementRef = useRef<HTMLDivElement | null>()
  const widgetRenderTargetPreviewImageElementRef = useRef<HTMLImageElement | null>()

  return (
    <Layout>
      <Typography variant="h4" align="center">
        Create new Pictogram
      </Typography>
      <Tabs value={currentTab} onChange={onTabChange}>
        {LOCIZE_ALL_LOCALES.map((locale) => (
          <Tab key={locale} label={locale.toUpperCase()} value={locale} />
        ))}
      </Tabs>
      <Card>
        <CardContent>
          <Grid container>
            <Grid item xs={6}>
              {LOCIZE_ALL_LOCALES.map((locale) => (
                <TabPanel key={locale} value={currentTab} index={locale}>
                  {navState
                    .toStrings()
                    .includes(
                      'loggedIn.buildPictogram.steps.describePictogram'
                    ) ? (
                    <DescribePictogram />
                  ) : navState
                      .toStrings()
                      .includes(
                        'loggedIn.buildPictogram.steps.defineSections'
                      ) ? (
                    <DefineSections />
                  ) : null}
                </TabPanel>
              ))}
            </Grid>
            <Grid item xs={6}>
              <div
                id="widget-container"
                ref={(ref) => (widgetContainerElementRef.current = ref)}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Stack direction="row" justifyContent="space-between">
            <Button
              onClick={() => navStateSend('BACK')}
              variant="outlined"
              color="primary"
            >
              Back
            </Button>
            {navState
              .toStrings()
              .includes('loggedIn.buildPictogram.steps.describePictogram') ? (
              <Button
                onClick={() => navStateSend('NEXT')}
                variant="contained"
                color="primary"
              >
                Continue
              </Button>
            ) : null}
            {navState
              .toStrings()
              .includes('loggedIn.buildPictogram.steps.defineSections') ? (
              <Button
                onClick={() => navStateSend('PREPARE_WIDGET_SAVE')}
                variant="contained"
                color="primary"
              >
                Create pictogram
              </Button>
            ) : null}
          </Stack>
          <Box
            sx={{
              mt: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Button onClick={onResetWidgetClick}>Reset form</Button>
            <Button onClick={onDummyClick}>
              Replace with dummy pictogram (this button will be removed!)
            </Button>
            <Button onClick={() => navStateSend('CANCEL')}>
              Back to main menu
            </Button>
          </Box>
        </CardContent>
      </Card>
      <SavePictogramModal
        widgetContainerElementRef={widgetContainerElementRef}
      />
    </Layout>
  )
}
