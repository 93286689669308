import { Box, Button, Container, Stack, Typography } from '@mui/material'
import { Layout } from '@nrcstat-monorepo/widget-builder/components'
import { z } from 'zod'
import { login } from '@nrcstat-monorepo/auth'

export function LoginForm() {
  return (
    <Layout>
      <Box sx={{ mt: 3 }}>
        <Typography variant="h5" align="center">
          Login
        </Typography>
        <Container maxWidth="sm" sx={{ mt: 5 }}>
          <Stack direction="column" spacing={2}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => login()}
            >
              Log in
            </Button>
          </Stack>
        </Container>
      </Box>
    </Layout>
  )
}
