import { QueryClient } from 'react-query'

export const queryClient = new QueryClient()

queryClient.setDefaultOptions({
  queries: {
    retry: (failureCount: number, error: unknown) => {
      if (error instanceof Error && error.name === 'UnauthorizedException')
        return false
      else return failureCount < 3
    },
    useErrorBoundary: true,
  },
  mutations: {
    retry: (failureCount: number, error: unknown) => {
      if (error instanceof Error && error.name === 'UnauthorizedException')
        return false
      else return failureCount < 3
    },
    useErrorBoundary: true,
  },
})
