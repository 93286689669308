import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useFetchWidgets } from '@nrcstat-monorepo/react-query/query'
import { Layout } from '@nrcstat-monorepo/widget-builder/components'
import { useAppNavigationService } from '@nrcstat-monorepo/widget-builder/machines'
import CardNewChart from '../../assets/card-new-chart.png'
import CardNewPictogram from '../../assets/card-new-pictogram.png'
import CardNewTimeline from '../../assets/card-new-timeline.png'
import { SavedWidgetsTable } from './SavedWidgetsTable'

const useStyles = makeStyles({
  grid: {
    marginTop: 68,
  },
  card: {
    maxWidth: 340,
  },
  cardImage: {
    height: 216,
  },
  cardTypography: {
    textTransform: 'uppercase',
    textAlign: 'center',
  },
})

export function Home() {
  const [navState, navStateSend] = useAppNavigationService()

  const classes = useStyles()

  return (
    <Layout>
      <Grid container spacing={3} className={classes.grid}>
        <Grid item xs={4}>
          <Card className={classes.card}>
            <CardActionArea onClick={() => navStateSend('BUILD_CHART')}>
              <CardMedia
                title="Create a new chart"
                image={CardNewChart}
                className={classes.cardImage}
              />
              <CardContent>
                <Typography variant="h6" className={classes.cardTypography}>
                  Create a new chart
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item xs={4}>
          <Card className={classes.card}>
            <CardActionArea onClick={() => navStateSend('BUILD_PICTOGRAM')}>
              <CardMedia
                title="Create a new pictogram"
                image={CardNewPictogram}
                className={classes.cardImage}
              />
              <CardContent>
                <Typography variant="h6" className={classes.cardTypography}>
                  Create a new Pictogram
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card className={classes.card}>
            <CardActionArea onClick={() => navStateSend('BUILD_TIMELINE')}>
              <CardMedia
                title="Create a new Timeline"
                image={CardNewTimeline}
                className={classes.cardImage}
              />
              <CardContent>
                <Typography variant="h6" className={classes.cardTypography}>
                  Create a new Timeline
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
      <Box sx={{ textAlign: 'center', my: 10 }}>
        <Typography variant="body1" sx={{ mb: 5 }}>
          You can use existing templates or previous visualisations as a basis
          for the new one.
        </Typography>
        <Button
          onClick={() => navStateSend('VIEW_TEMPLATES')}
          variant="contained"
        >
          Open templates & library
        </Button>
      </Box>
      <SavedWidgetsTable />
      <Button
        onClick={() => navStateSend('PREPARE_COUNTRY_SIDEBAR_ID')}
        variant="contained"
      >
        Prepare Country Sidebar ID
      </Button>
    </Layout>
  )
}
