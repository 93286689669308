import { initAuthModule } from '@nrcstat-monorepo/auth'
import { StrictMode } from 'react'
import * as ReactDOM from 'react-dom'
import App from './app/App'

initAuthModule().then(() => {
  ReactDOM.render(
    <StrictMode>
      <App />
    </StrictMode>,
    document.getElementById('root')
  )
})
