import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Typography,
  Slider,
  SelectChangeEvent,
  Box,
  ListSubheader,
  Stack,
} from '@mui/material'
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  DataColor,
  Icon,
  PictogramSectionIcon,
} from '@nrcstat-monorepo/config-and-definitions'
import {
  useAppNavigationService,
  usePictogramBuilderService,
} from '@nrcstat-monorepo/widget-builder/machines'
import { useCallback } from 'react'

import { ReactComponent as Camp } from './assets/Camp.svg'
import { ReactComponent as Education } from './assets/Education.svg'
import { ReactComponent as Food } from './assets/Food.svg'
import { ReactComponent as Legal } from './assets/Legal.svg'
import { ReactComponent as PeopleChildren } from './assets/PeopleChildren.svg'
import { ReactComponent as PeopleFemale } from './assets/PeopleFemale.svg'
import { ReactComponent as PeopleLittleBoy } from './assets/PeopleLittleBoy.svg'
import { ReactComponent as PeopleLittleGirl } from './assets/PeopleLittleGirl.svg'
import { ReactComponent as PeopleMale } from './assets/PeopleMale.svg'
import { ReactComponent as PeopleRefugeeFamily } from './assets/PeopleRefugeeFamily.svg'
import { ReactComponent as PeopleRefugeeFamilyAlt } from './assets/PeopleRefugeeFamilyAlt.svg'
import { ReactComponent as PeopleRefugeesRunning } from './assets/PeopleRefugeesRunning.svg'
import { ReactComponent as PeopleSchoolBoy } from './assets/PeopleSchoolBoy.svg'
import { ReactComponent as PeopleSchoolGirl } from './assets/PeopleSchoolGirl.svg'
import { ReactComponent as PeopleChildrenCircle } from './assets/PeopleChildrenCircle.svg'
import { ReactComponent as PeopleFemaleCircle } from './assets/PeopleFemaleCircle.svg'
import { ReactComponent as PeopleLittleBoyCircle } from './assets/PeopleLittleBoyCircle.svg'
import { ReactComponent as PeopleLittleGirlCircle } from './assets/PeopleLittleGirlCircle.svg'
import { ReactComponent as PeopleMaleCircle } from './assets/PeopleMaleCircle.svg'
import { ReactComponent as PeopleRefugeeFamilyCircle } from './assets/PeopleRefugeeFamilyCircle.svg'
import { ReactComponent as PeopleRefugeeFamilyAltCircle } from './assets/PeopleRefugeeFamilyAltCircle.svg'
import { ReactComponent as PeopleRefugeesRunningCircle } from './assets/PeopleRefugeesRunningCircle.svg'
import { ReactComponent as PeopleSchoolBoyCircle } from './assets/PeopleSchoolBoyCircle.svg'
import { ReactComponent as PeopleSchoolGirlCircle } from './assets/PeopleSchoolGirlCircle.svg'
import { ReactComponent as Shelter } from './assets/Shelter.svg'
import { ReactComponent as WASH } from './assets/WASH.svg'
import { grey } from '@mui/material/colors'

const ICONS = {
  Camp: Camp,
  Education: Education,
  Food: Food,
  PeopleChildren: PeopleChildren,
  PeopleFemale: PeopleFemale,
  PeopleLittleBoy: PeopleLittleBoy,
  PeopleLittleGirl: PeopleLittleGirl,
  PeopleMale: PeopleMale,
  PeopleRefugeeFamily: PeopleRefugeeFamily,
  PeopleRefugeeFamilyAlt: PeopleRefugeeFamilyAlt,
  PeopleRefugeesRunning: PeopleRefugeesRunning,
  PeopleSchoolBoy: PeopleSchoolBoy,
  PeopleSchoolGirl: PeopleSchoolGirl,
  PeopleChildrenCircle: PeopleChildrenCircle,
  PeopleFemaleCircle: PeopleFemaleCircle,
  PeopleLittleBoyCircle: PeopleLittleBoyCircle,
  PeopleLittleGirlCircle: PeopleLittleGirlCircle,
  PeopleMaleCircle: PeopleMaleCircle,
  PeopleRefugeeFamilyCircle: PeopleRefugeeFamilyCircle,
  PeopleRefugeeFamilyAltCircle: PeopleRefugeeFamilyAltCircle,
  PeopleRefugeesRunningCircle: PeopleRefugeesRunningCircle,
  PeopleSchoolBoyCircle: PeopleSchoolBoyCircle,
  PeopleSchoolGirlCircle: PeopleSchoolGirlCircle,
  Legal: Legal,
  Shelter: Shelter,
  WASH: WASH,
}

const ICON_LABELS = {
  Camp: 'Camp',
  Education: 'Education',
  Food: 'Food',
  Legal: 'Legal',
  Shelter: 'Shelter',
  WASH: 'WASH',
  PeopleChildren: 'Children',
  PeopleFemale: 'Female',
  PeopleLittleBoy: 'Boy',
  PeopleLittleGirl: 'Girl',
  PeopleMale: 'Male',
  PeopleRefugeeFamily: 'Refugee Family',
  PeopleRefugeeFamilyAlt: 'Refugee Family 2',
  PeopleRefugeesRunning: 'Refugees Running',
  PeopleSchoolBoy: 'Schoolboy',
  PeopleSchoolGirl: 'Schoolgirl',
  PeopleChildrenCircle: 'Children',
  PeopleFemaleCircle: 'Female',
  PeopleLittleBoyCircle: 'Boy',
  PeopleLittleGirlCircle: 'Girl',
  PeopleMaleCircle: 'Male',
  PeopleRefugeeFamilyCircle: 'Refugee Family',
  PeopleRefugeeFamilyAltCircle: 'Refugee Family 2',
  PeopleRefugeesRunningCircle: 'Refugees Running',
  PeopleSchoolBoyCircle: 'Schoolboy',
  PeopleSchoolGirlCircle: 'Schoolgirl',
}

const iconGroupsLabels = [
  [
    'Core Activities',
    ['Camp', 'Education', 'Food', 'Legal', 'Shelter', 'WASH'],
  ],
  [
    'Humanitarian icons',
    [
      'PeopleChildren',
      'PeopleFemale',
      'PeopleLittleBoy',
      'PeopleLittleGirl',
      'PeopleMale',
      'PeopleRefugeeFamily',
      'PeopleRefugeeFamilyAlt',
      'PeopleRefugeesRunning',
      'PeopleSchoolBoy',
      'PeopleSchoolGirl',
    ],
  ],
  [
    'Humanitarian icons w/ circle',
    [
      'PeopleChildrenCircle',
      'PeopleFemaleCircle',
      'PeopleLittleBoyCircle',
      'PeopleLittleGirlCircle',
      'PeopleMaleCircle',
      'PeopleRefugeeFamilyCircle',
      'PeopleRefugeeFamilyAltCircle',
      'PeopleRefugeesRunningCircle',
      'PeopleSchoolBoyCircle',
      'PeopleSchoolGirlCircle',
    ],
  ],
]

export function DefineSections() {
  const [, navStateSend] = useAppNavigationService()
  const [pictogramBuilder, pictogramBuilderSend] = usePictogramBuilderService()

  const sections = pictogramBuilder.context.widget.sections
  const activeLocale = pictogramBuilder.context.activeLocale

  const onTitleChange = useCallback(
    (uniqKey: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const section = sections.find((section) => section.uniqKey === uniqKey)
      if (!section) return
      const value = event.target.value
      pictogramBuilderSend({
        type: 'SECTION_UPDATE_TITLE',
        uniqKey,
        title: value,
      })
    },
    [sections, pictogramBuilderSend]
  )

  const onIconFieldChange = (
    sectionKey: string,
    iconKey: string,
    field: keyof Omit<PictogramSectionIcon, 'uniqKey'>
  ) => (event: SelectChangeEvent) => {
    const value = event.target.value as string | number
    pictogramBuilderSend({
      type: 'SECTION_ICON_FIELD_CHANGE',
      sectionKey,
      iconKey,
      field,
      value,
    })
  }

  const onIconValueChange = (sectionKey: string, iconKey: string) => (
    event: unknown,
    value: number | number[]
  ) => {
    const singleValue = value as number
    pictogramBuilderSend({
      type: 'SECTION_ICON_FIELD_CHANGE',
      sectionKey,
      iconKey,
      field: 'data',
      value: singleValue,
    })
  }

  return (
    <>
      {sections.map((section, index) => (
        <Accordion key={section.uniqKey} disableGutters sx={{ mb: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ '& .MuiAccordionSummary-content': { my: 0 } }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                ml: 1,
              }}
            >
              <Typography variant="body1" sx={{ flexGrow: 1 }}>
                {section?.title[activeLocale]
                  ? section.title[activeLocale]
                  : 'New section'}
              </Typography>
              <ButtonGroup
                color="primary"
                orientation="vertical"
                variant="text"
              >
                <IconButton
                  size="small"
                  disabled={sections.length < 2 || index === 0}
                  onClick={(e) => {
                    e.stopPropagation()
                    pictogramBuilderSend({
                      type: 'SECTION_REORDER',
                      direction: 'up',
                      index: index,
                    })
                  }}
                  sx={{ p: 0 }}
                >
                  <ArrowDropUpIcon sx={{ color: 'white' }} />
                </IconButton>
                <IconButton
                  size="small"
                  disabled={
                    sections.length < 2 || index + 1 === sections.length
                  }
                  onClick={(e) => {
                    e.stopPropagation()
                    pictogramBuilderSend({
                      type: 'SECTION_REORDER',
                      direction: 'down',
                      index: index,
                    })
                  }}
                  sx={{ p: 0 }}
                >
                  <ArrowDropDownIcon sx={{ color: 'white' }} />
                </IconButton>
              </ButtonGroup>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <TextField
                fullWidth
                margin="normal"
                label="Title"
                variant="outlined"
                onChange={onTitleChange(section.uniqKey)}
                value={section.title[activeLocale]}
              />

              {section.icons.map((icon) => (
                <Paper
                  variant="outlined"
                  sx={{
                    backgroundColor: grey[200],
                    width: '100%',
                    mt: 1,
                    p: 2,
                  }}
                  key={icon.uniqKey}
                >
                  <Stack direction="row" spacing={3}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Stack direction="row" spacing={1}>
                        <FormControl sx={{ flexGrow: 3 }} variant="standard">
                          <InputLabel>Select icon</InputLabel>
                          <Select
                            fullWidth
                            value={icon.icon}
                            onChange={onIconFieldChange(
                              section.uniqKey,
                              icon.uniqKey,
                              'icon'
                            )}
                            renderValue={(iconKey) => {
                              const IconComponent = ICONS[iconKey]
                              const iconLabel = ICON_LABELS[iconKey]

                              return (
                                <MenuItem key={iconKey} value={iconKey}>
                                  <IconComponent
                                    style={{
                                      height: '40px',
                                      width: '40px',
                                      marginRight: '10px',
                                    }}
                                  />
                                  {iconLabel}
                                </MenuItem>
                              )
                            }}
                          >
                            {Array.from(iconGroupsLabels).map(
                              ([label, icons]) => [
                                <ListSubheader>{label}</ListSubheader>,
                                ...(icons as Icon[]).map((iconKey: Icon) => {
                                  const IconComponent = ICONS[iconKey]
                                  const iconLabel = ICON_LABELS[iconKey]

                                  return (
                                    <MenuItem key={iconKey} value={iconKey}>
                                      <IconComponent
                                        style={{
                                          height: '40px',
                                          width: '40px',
                                          marginRight: '10px',
                                        }}
                                      />
                                      {iconLabel}
                                    </MenuItem>
                                  )
                                  return <IconMenuItem iconKey={iconKey} />
                                }),
                              ]
                            )}
                          </Select>
                        </FormControl>
                        <FormControl sx={{ flexGrow: 1 }} variant="standard">
                          <InputLabel>Select colour</InputLabel>
                          <Select
                            fullWidth
                            value={icon.dataColor}
                            onChange={onIconFieldChange(
                              section.uniqKey,
                              icon.uniqKey,
                              'dataColor'
                            )}
                          >
                            {Object.values(DataColor).map((colour) => {
                              return (
                                <MenuItem key={colour} value={colour}>
                                  <div
                                    style={{
                                      height: '20px',
                                      width: '20px',
                                      backgroundColor: colour,
                                    }}
                                  />
                                </MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                      </Stack>
                      <Typography variant="body1" sx={{ mt: 3 }}>
                        Value (in %):
                      </Typography>
                      <FormControl fullWidth>
                        <InputLabel>{Math.floor(icon.data * 100)}%</InputLabel>
                        <Slider
                          defaultValue={0}
                          valueLabelDisplay="auto"
                          valueLabelFormat={(value) =>
                            `${Math.floor(value * 100)}%`
                          }
                          step={0.05}
                          marks
                          min={0}
                          max={1}
                          onChange={onIconValueChange(
                            section.uniqKey,
                            icon.uniqKey
                          )}
                          value={icon.data}
                        />
                      </FormControl>
                    </Box>
                    <Box>
                      <IconButton
                        onClick={() =>
                          pictogramBuilderSend({
                            type: 'SECTION_REMOVE_ICON',
                            sectionKey: section.uniqKey,
                            iconKey: icon.uniqKey,
                          })
                        }
                        color="primary"
                        sx={{
                          position: 'relative',
                          top: '-13px',
                          right: '-13px',
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Stack>
                </Paper>
              ))}

              <Button
                onClick={() =>
                  pictogramBuilderSend({
                    type: 'SECTION_ADD_ICON',
                    sectionKey: section.uniqKey,
                  })
                }
                variant="text"
                sx={{ mt: 2 }}
              >
                <AddIcon />
                Add row
              </Button>

              <Grid container justifyContent="flex-end">
                <Button
                  startIcon={<DeleteIcon />}
                  onClick={() =>
                    pictogramBuilderSend({
                      type: 'SECTION_REMOVE',
                      uniqKey: section.uniqKey,
                    })
                  }
                >
                  Delete section
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
      <Grid container justifyContent="center">
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => pictogramBuilderSend('SECTION_ADD')}
        >
          Add section
        </Button>
      </Grid>
    </>
  )
}

function IconMenuItem({ iconKey }: { iconKey: Icon }) {
  const IconComponent = ICONS[iconKey]
  const iconLabel = ICON_LABELS[iconKey]

  return (
    <MenuItem
      key={iconKey}
      value={iconKey}
      // sx={{ display: 'flex', alignItems: 'center' }}
    >
      <IconComponent
        style={{
          height: '40px',
          width: '40px',
          marginRight: '10px',
        }}
      />
      {iconLabel}
    </MenuItem>
  )
}
