import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@mui/material'
import { styled } from '@mui/styles'
import { WidgetBase } from '@nrcstat-monorepo/config-and-definitions'
import { useFetchWidgets } from '@nrcstat-monorepo/react-query/query'
import { Layout } from '@nrcstat-monorepo/widget-builder/components'
import { useAppNavigationService } from '@nrcstat-monorepo/widget-builder/machines'
import { SavedWidgetsTable } from './SavedWidgetsTable'
import { useCreateNewWidgetFromWidget } from './WidgetInfoActionModal'

export function Templates() {
  const [navState, navStateSend] = useAppNavigationService()

  return (
    <Layout>
      <Button onClick={() => navStateSend('BACK')} sx={{ mt: 2 }}>
        Back to home
      </Button>
      <VerticalSpacer>
        <Typography variant="h4" align="center">
          Templates
        </Typography>
        <Typography variant="body1" align="center">
          Use templates for faster visualisation creation.
        </Typography>
      </VerticalSpacer>
      <VerticalSpacer>
        <TemplateGrid />
      </VerticalSpacer>
      <SavedWidgetsTable />
      <Button onClick={() => navStateSend('BACK')} sx={{ mt: 4 }}>
        Back to home
      </Button>
    </Layout>
  )
}

function TemplateGrid() {
  const { data: widgets } = useFetchWidgets()

  const templateWidgets = widgets?.filter((w) => w.isTemplate)

  console.log(templateWidgets)

  return (
    <Grid container spacing={2}>
      {templateWidgets?.map((widget) => (
        <TemplateGridItem widget={widget} />
      ))}
    </Grid>
  )
}

function TemplateGridItem({ widget }: { widget: WidgetBase }) {
  const handleCreateNewWidgetFromWidget = useCreateNewWidgetFromWidget(widget)

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card sx={{ p: 1.5 }}>
        <CardMedia
          component="img"
          // height={200}
          image={
            widget.cloudinaryThumbnailUrl
              ? buildCloudinaryThumbnailUrl(widget.cloudinaryThumbnailUrl)
              : ''
          }
        />
        <CardContent>
          <Typography gutterBottom variant="body1" component="div">
            {widget.internalName}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            onClick={() => handleCreateNewWidgetFromWidget()}
            size="small"
            // sx={{ mt: 2, px: 0 }}
          >
            Use template
          </Button>
        </CardActions>
      </Card>
    </Grid>
  )
}

function UseTemplateButton({ widget }: { widget: WidgetBase }) {}

const VerticalSpacer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(4),
}))

function buildCloudinaryThumbnailUrl(url: string) {
  return url.replace(
    '/upload/',
    '/upload/e_trim/bo_25px_solid_white/c_lpad,ar_1/'
  )
}
