import { putWidget } from '@nrcstat-monorepo/api'
import { WidgetBase } from '@nrcstat-monorepo/config-and-definitions'
import { useMutation, useQueryClient } from 'react-query'

export function usePutWidget<Widget extends WidgetBase>() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ widgetId, updates }: { widgetId: string; updates: Partial<Widget> }) =>
      putWidget<Widget>({ widgetId, updates }),
    {
      onSuccess: (widget) => {
        queryClient.setQueryData(['widget', widget.id], widget)
        queryClient.setQueryData(
          'widgets',
          (old: WidgetBase[] | undefined) =>
            old?.map((w) => (w.id === widget.id ? widget : w)) ?? []
        )
      },
    }
  )
}
