import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import {
  LocizeLocale,
  LOCIZE_ALL_LOCALES,
} from '@nrcstat-monorepo/config-and-definitions'
import { Layout } from '@nrcstat-monorepo/widget-builder/components'
import {
  useAppNavigationService,
  useTimelineBuilderService,
} from '@nrcstat-monorepo/widget-builder/machines'
import React, { useRef } from 'react'
import { useWidgetPreview } from '../../hooks/use-widget-preview'
import { SaveTimelineModal } from './SaveTimelineModal'
import { TimelineEntriesForm } from './TimelineEntriesForm'

export function BuildTimeline() {
  const [, navStateSend] = useAppNavigationService()

  const [timelineBuilder, timelineBuilderSend] = useTimelineBuilderService()
  const { activeLocale } = timelineBuilder.context

  const currentTab = activeLocale

  useWidgetPreview(
    '#widget-container',
    timelineBuilder.context.widget,
    activeLocale
  )

  const widgetContainerElementRef = useRef<HTMLDivElement>(null)

  // TODO: Temporary, remove me
  const onDummyClick = () => {
    timelineBuilderSend({
      type: 'REPLACE_WIDGET',
      widget: {
        internalName: '',
        title: {},
        socialMediaDescription: {},
        type: 'timeline',
        entries: [
          {
            uniqKey: '6ebcb3ac-fba7-4114-b3d8-f7904978178a',
            title: {
              'nb-NO': '1946',
            },
            subtitle: {
              'nb-NO': 'Europahjelpen startes',
            },
            body: {
              'nb-NO':
                'Når andre verdenskrig er over i 1945, er 36 millioner europeere fordrevet eller hjemløse. Ytterligere 27 millioner har blitt uføre, og sult og tuberkulose herjer på kontinentet. I 1946 startes Europahjelpen, forløperen til Flyktninghjelpen. Initiativet blir tatt av Norsk Folkehjelp og Norges Røde Kors.',
            },
          },
          {
            uniqKey: '3dcf45df-967a-44f0-a6f4-d50960c986e1',
            title: {
              'nb-NO': '1951',
            },
            subtitle: {
              'nb-NO': 'Flyktningkonvensjonen',
            },
            body: {
              'nb-NO':
                'FN vedtar Flyktningkonvensjonen. FNs Høykommissær for Flyktninger (UNHCR) opprettes.',
            },
          },
          {
            uniqKey: 'f39fc5f0-66a6-4846-aa82-736ec1c9b643',
            title: {
              'nb-NO': '1953',
            },
            subtitle: {
              'nb-NO': 'Det Norske Flyktningråd',
            },
            body: {
              'nb-NO':
                'Det norske Flyktningeråd opprettes, og overtar etter Europahjelpen. Flyktningerådet definerer to hovedoppgaver: Hjelpe flyktningene ute i verden, og hjelpe flyktningene i Norge.',
            },
          },
          {
            uniqKey: '52758eb9-01bb-4c4d-be71-97d971d3300f',
            title: {
              'nb-NO': '1956',
            },
            subtitle: {
              'nb-NO': 'Norge mottar ungarske flyktninger',
            },
            body: {
              'nb-NO':
                'Sovjetunionen slår ned opprøret i Ungarn, og mange flykter. Norske myndigheter går med på å ta imot 1500 ungarere i Norge. Flyktningerådet bistår både i Norge og i flyktningleirer i Østerrike.',
            },
          },
        ],
        isTemplate: false,
      },
    })
    timelineBuilderSend({ type: 'CHANGE_ACTIVE_LOCALE', newLocale: 'nb-NO' })
  }

  const onResetWidgetClick = () =>
    window.confirm('Are you sure?') && timelineBuilderSend('RESET')

  const onTabChange = (_: unknown, newTab: LocizeLocale) => {
    timelineBuilderSend({ type: 'CHANGE_ACTIVE_LOCALE', newLocale: newTab })
  }

  return (
    <Layout>
      <Typography variant="h4" align="center">
        Create new Timeline
      </Typography>
      <Card>
        <Tabs value={currentTab} onChange={onTabChange}>
          {LOCIZE_ALL_LOCALES.map((locale) => (
            <Tab key={locale} label={locale.toUpperCase()} value={locale} />
          ))}
        </Tabs>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <TimelineEntriesForm />
            </Grid>
            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'end' }}>
              <Paper sx={{ width: '280px', p: 2 }}>
                <div id="widget-container" ref={widgetContainerElementRef} />
              </Paper>
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Stack direction="row" justifyContent="space-between">
            <Button onClick={() => navStateSend('CANCEL')} variant="outlined">
              Back
            </Button>
            <Button
              onClick={() => navStateSend('PREPARE_WIDGET_SAVE')}
              variant="contained"
            >
              Create timeline
            </Button>
          </Stack>
          <Box
            sx={{
              mt: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Button onClick={onResetWidgetClick}>Reset form</Button>
            <Button onClick={onDummyClick}>
              Replace with dummy timeline (this button will be removed!)
            </Button>
          </Box>
        </CardContent>
      </Card>
      <SaveTimelineModal
        widgetContainerElementRef={widgetContainerElementRef}
      />
    </Layout>
  )
}
