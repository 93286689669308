import {
  AUTH0_CLIENTID,
  AUTH0_DOMAIN,
  DATA_API_URL,
} from '@nrcstat-monorepo/config-and-definitions'
import { createAuth0Client, Auth0Client, User } from '@auth0/auth0-spa-js'
import { useQuery } from 'react-query'

let globallyCachedAuth0Client: Auth0Client | null = null
function auth0Client() {
  return globallyCachedAuth0Client as Auth0Client
}
export async function initAuthModule() {
  globallyCachedAuth0Client = await createAuth0Client({
    domain: AUTH0_DOMAIN,
    clientId: AUTH0_CLIENTID,
    authorizationParams: {
      redirect_uri: window.location.origin,
      scope: 'openid profile email',
      audience: 'https://data.nrcdata.no/',
    },
  })
  const client = auth0Client()

  if (
    location.search.includes('state=') &&
    (location.search.includes('code=') || location.search.includes('error='))
  ) {
    await client.handleRedirectCallback()
    console.log('detected post-auth redirect')
    const user = await client.getUser()
    const accessToken = await client.getTokenSilently()
    console.log('user', user)
    console.log('accessToken', accessToken)
    window.history.replaceState({}, document.title, '/')
  }

  ;(window as any).auth = globallyCachedAuth0Client
}

export async function getAccessToken(): Promise<string | null> {
  const client = auth0Client()
  const accessToken = await client.getTokenSilently({})
  return accessToken
}

export async function isLoggedIn(): Promise<boolean> {
  const client = auth0Client()
  return client.isAuthenticated()
}
export function useIsLoggedIn() {
  return useQuery('isLoggedIn', isLoggedIn)
}

export async function getCurrentUser(): Promise<User | undefined> {
  const client = auth0Client()
  const user = await client.getUser()
  const claims = await client.getIdTokenClaims()
  console.log(claims)
  return user
}
export function useCurrentUser() {
  return useQuery('currentUser', getCurrentUser)
}

export async function login() {
  const client = auth0Client()
  client.loginWithRedirect()
}
export async function logout() {
  const client = auth0Client()
  client.logout({ logoutParams: { returnTo: window.location.origin } })
}

// I want an expressjs app. It should have four CRUD endpoints to read/write data to Salesforce, using the jsforce library. All read/write operations are on the Salesforce Contact object. Please provide all this code in a single file. Include initialization of express and jsforce.
