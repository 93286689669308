import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import {
  GlobalDisplacementDataPointId,
  GorsDataPointId,
  Year,
  Years,
} from '@nrcstat-monorepo/config-and-definitions'
import { useI18n } from '@nrcstat-monorepo/i18n'
import { useCountries } from '@nrcstat-monorepo/react-query/query'
import { toggleValueInArray } from '@nrcstat-monorepo/utils'
import { useChartBuilderService } from '@nrcstat-monorepo/widget-builder/machines'
import React from 'react'

const globalDisplacementDataPoints = Object.values(
  GlobalDisplacementDataPointId
)
const gorsDataPoints = Object.values(GorsDataPointId)

export function AvailableData() {
  console.log(gorsDataPoints)

  const t = useI18n()

  const { data: countries, isSuccess: countryDataLoaded } = useCountries()

  const [chartBuilder, chartBuilderSend] = useChartBuilderService()

  const {
    widget: {
      autoData: {
        dataPoints: selectedDataPoints,
        countries: selectedCountries,
        years: selectedYears,
      },
    },
  } = chartBuilder.context

  const newPoints = [
    'Camp Management',
    'Education',
    'ICLA',
    'LFS',
    'Shelter',
    'WASH',
    'Other',
  ]

  if (!countryDataLoaded || !countries) return null
  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography variant="h4" sx={{ mt: 0 }}>
          Data points
        </Typography>
        <Typography variant="h6">Global displacement</Typography>
        <FormControl>
          <FormGroup>
            {globalDisplacementDataPoints.map((dataPointId) => (
              <FormControlLabel
                key={dataPointId}
                control={
                  <Checkbox
                    checked={selectedDataPoints.includes(dataPointId)}
                    onChange={() =>
                      chartBuilderSend({
                        type: 'UPDATE_DATA_POINTS',
                        dataPoints: toggleValueInArray<
                          GlobalDisplacementDataPointId | GorsDataPointId
                        >(selectedDataPoints, dataPointId),
                      })
                    }
                  />
                }
                label={
                  t(`Glossary:dataPoint.${dataPointId}.shortLabel`) as string
                }
              />
            ))}
          </FormGroup>
        </FormControl>
        <Typography variant="h5" sx={{ mt: 3 }}>
          GORS data
        </Typography>
        <FormControl>
          <FormGroup>
            {gorsDataPoints.map((dataPointId) => (
              <FormControlLabel
                key={dataPointId}
                control={
                  <Checkbox
                    checked={selectedDataPoints.includes(dataPointId)}
                    onChange={() =>
                      chartBuilderSend({
                        type: 'UPDATE_DATA_POINTS',
                        dataPoints: toggleValueInArray<
                          GlobalDisplacementDataPointId | GorsDataPointId
                        >(selectedDataPoints, dataPointId),
                      })
                    }
                  />
                }
                label={
                  t(`Glossary:dataPoint.${dataPointId}.shortLabel`) as string
                }
              />
            ))}
          </FormGroup>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <Box mb={10}>
          <Typography variant="h5">Country selection</Typography>
          <Typography variant="subtitle1">
            Select all countries for which you want to show data
          </Typography>
          <FormControl variant="filled" fullWidth>
            <InputLabel>Select countries</InputLabel>
            <Select
              fullWidth
              multiple
              value={selectedCountries}
              onChange={(e) => {
                const countries = e.target.value as string[]
                chartBuilderSend({
                  type: 'UPDATE_COUNTRIES',
                  countries,
                })
              }}
            >
              {countries.map(({ code2, name }) => (
                <MenuItem key={code2} value={code2}>
                  {name}
                  {/* {t(
                    `GeographicalNames:NRC.Web.StaticTextDictionary.Contries.${code2}`
                  )} */}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectedCountries.map((iso2, index) => (
            <Chip
              data-iso2={iso2}
              key={index}
              label={t(
                `GeographicalNames:NRC.Web.StaticTextDictionary.Contries.${iso2}`
              )}
              onDelete={() =>
                chartBuilderSend({
                  type: 'UPDATE_COUNTRIES',
                  countries: selectedCountries.filter((c) => c !== iso2),
                })
              }
            />
          ))}
        </Box>

        <Typography variant="h5">Year selection</Typography>
        <Typography variant="subtitle1">
          Select all years for which you want to show data
        </Typography>
        <FormControl>
          <FormGroup row>
            {Years.map((year) => (
              <FormControlLabel
                key={year}
                control={
                  <Checkbox
                    checked={selectedYears.includes(year)}
                    onChange={() =>
                      chartBuilderSend({
                        type: 'UPDATE_YEARS',
                        years: toggleValueInArray<Year>(selectedYears, year),
                      })
                    }
                  />
                }
                label={String(year)}
              />
            ))}
          </FormGroup>
        </FormControl>
      </Grid>
    </Grid>
  )
}
