import { fetchWidgets } from '@nrcstat-monorepo/api'
import { WidgetBase } from '@nrcstat-monorepo/config-and-definitions'
import { useQuery } from 'react-query'

export function useFetchWidgets(query?: string) {
  return useQuery<WidgetBase[]>(
    ['widgets', { query }],
    () => fetchWidgets(query),
    {
      // staleTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
    }
  )
}
