import { DATA_API_URL } from '@nrcstat-monorepo/config-and-definitions'
import { apiFetch } from './utils/api-fetch'

export async function deleteWidget(widgetId: string) {
  await apiFetch(`${DATA_API_URL}/widgets/${widgetId}`, {
    method: 'DELETE',
  })

  return null
}
