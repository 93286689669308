/* eslint-disable jsx-a11y/accessible-emoji */
import { Stack, Typography } from '@mui/material'
import { logout } from '@nrcstat-monorepo/auth'
import { useAppNavigationService } from '@nrcstat-monorepo/widget-builder/machines'
import { useCallback, useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Layout } from './Layout'

interface Props {
  children: React.ReactNode
}

export function RootLevelErrorBoundary({ children }: Props) {
  const [errorWasCaught, setErrorWasCaught] = useState<boolean>(false)

  return (
    <ErrorBoundary
      FallbackComponent={ErrorMessage}
      resetKeys={[errorWasCaught]}
      onReset={() => setErrorWasCaught(false)}
    >
      {errorWasCaught ? null : children}
    </ErrorBoundary>
  )
}

function ErrorMessage({
  error,
  resetErrorBoundary,
}: {
  error: Error
  resetErrorBoundary: () => void
}) {
  const [, navStateSend] = useAppNavigationService()
  useEffect(() => {
    if (error.name === 'UnauthorizedException') {
      logout()
      navStateSend('LOGOUT')
      resetErrorBoundary()
    }
  }, [error.name, navStateSend, resetErrorBoundary])

  return (
    <Layout>
      <Stack spacing={2} sx={{ mt: 2 }}>
        <Typography variant="h6">Oops 🤯</Typography>
        <Typography variant="body1">
          Sorry, an unexpected error occured.
        </Typography>
        <Typography variant="body1">
          Please refresh and try again. If an error still persists, get in touch
          with <a href="mailto:lars.torjesen@nrc.no">Lars Torjesen</a> or{' '}
          <a href="mailto:eric@binarylights.com">Eric Bolikowski</a>.
        </Typography>
      </Stack>
    </Layout>
  )
}
