import { GlobalDisplacementDataPointId } from '../global-displacement-data-point-id'
import { GorsDataPointId } from '../gors-data-point-id'
import { LocizeLocale } from '../locize-locale'
import { WidgetBase } from './widget-base'
import { WidgetType } from './widget-type'

type Extends<T, U extends T> = U

export interface ChartWidget extends WidgetBase {
  type: Extends<WidgetType, 'bar' | 'column' | 'donut' | 'line' | 'table'>
  config: {
    subtitle: Partial<Record<LocizeLocale, string>>
    source: Partial<Record<LocizeLocale, string>>
    linkToSource: string
  }
  autoData: {
    dataPoints: Array<GlobalDisplacementDataPointId | GorsDataPointId>
    countries: Array<string>
    years: Array<number>
  }
  customData: {
    columns: Array<CustomDataColumnSettings>
    data: Array<CustomDataRowObject>
  }
  dataType: WidgetDataType
  enablePopup: boolean
  enableColourSchemeOverride: boolean
  overridingColourScheme: string
}

export interface CustomDataColumnSettings {
  data: string // this is actually the data point id
  columnLabel: Partial<Record<LocizeLocale, string>>
  renderer?: string
  type?: string
}
export interface CustomDataColumnSettingsLocaleLess {
  data: string // this is actually the data point id
  columnLabel: string
  renderer?: string
  type?: string
}

export type DataKey = `data_${string}`
export interface CustomDataRowObject {
  id: string
  label: Partial<Record<LocizeLocale, string>>
  [key: DataKey]: number
}
export interface CustomDataRowObjectLocaleLess {
  id: string
  label: string
  [key: DataKey]: number
}

export const WidgetDataType = {
  custom: 'custom',
  auto: 'auto',
} as const
export type WidgetDataType = typeof WidgetDataType[keyof typeof WidgetDataType]

export function isWidgetChartWidget(
  widget?: WidgetBase
): widget is ChartWidget {
  return (
    widget?.type === 'bar' ||
    widget?.type === 'column' ||
    widget?.type === 'line' ||
    widget?.type === 'donut' ||
    widget?.type === 'table'
  )
}
