import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { PictogramWidget } from '@nrcstat-monorepo/config-and-definitions'
import {
  usePostWidgetMutation,
  usePutWidget,
} from '@nrcstat-monorepo/react-query/query'
import {
  useAppNavigationService,
  usePictogramBuilderService,
} from '@nrcstat-monorepo/widget-builder/machines'
import {
  buildShareableHtmlPage,
  uploadWidgetHtmlFileToS3Bucket,
  useMutationUploadWidgetThumbnailToCloudinary,
  useRenderWidgetThumbnailBlob,
} from '@nrcstat-monorepo/widget-social-media-sharing'
import React, { useCallback, useRef } from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
interface Props {
  widgetContainerElementRef: React.MutableRefObject<
    HTMLDivElement | null | undefined
  >
}

export function SavePictogramModal(props: Props) {
  const [navState, navStateSend] = useAppNavigationService()
  const [, pictogramBuilderSend] = usePictogramBuilderService()

  const isOpen =
    navState.matches('loggedIn.buildPictogram.saveModal.specifyInternalName') ||
    navState.matches(
      'loggedIn.buildPictogram.saveModal.widgetSavedConfirmation'
    )

  const handleClose = useCallback(() => {
    const hasWidgetBeenSaved = navState.matches(
      'loggedIn.buildPictogram.saveModal.widgetSavedConfirmation'
    )
    if (hasWidgetBeenSaved) {
      navStateSend('CANCEL')
      pictogramBuilderSend('RESET')
    } else {
      navStateSend('CLOSE_SAVE_MODAL')
    }
  }, [navState, navStateSend, pictogramBuilderSend])

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      {navState.matches(
        'loggedIn.buildPictogram.saveModal.specifyInternalName'
      ) ? (
        <SpecifyInternalName {...props} />
      ) : null}
      {navState.matches(
        'loggedIn.buildPictogram.saveModal.widgetSavedConfirmation'
      ) ? (
        <WidgetSaveConfirmation />
      ) : null}
    </Dialog>
  )
}

function SpecifyInternalName({ widgetContainerElementRef }: Props) {
  const [, navStateSend] = useAppNavigationService()
  const [pictogramBuilder, pictogramBuilderSend] = usePictogramBuilderService()
  const postWidget = usePostWidgetMutation<PictogramWidget>()
  const putWidget = usePutWidget<PictogramWidget>()
  const renderWidgetThumbnailBlob = useRenderWidgetThumbnailBlob()
  const uploadWidgetHtmlFileToS3 = uploadWidgetHtmlFileToS3Bucket()
  const uploadWidgetThumbnailToCloudinary = useMutationUploadWidgetThumbnailToCloudinary()

  const pictogramWidget = pictogramBuilder.context.widget

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    pictogramBuilderSend({
      type: 'CHANGE_INTERNAL_NAME',
      internalName: event.target.value,
    })
  }

  const onWidgetSave = async () => {
    const savedWidgetObject = await postWidget.mutateAsync(pictogramWidget)

    // We expect that the widget has already been fully rendered into #widget-container
    // as done by the <BuildPictogram> function. We further expect that inside of that
    // element there is a div element with the class "container" .
    // These dependencies should maybe be made more explicit and formalized into code?
    const thumbnailImageDataUrl = await renderWidgetThumbnailBlob.mutateAsync(
      widgetContainerElementRef
    )

    if (!thumbnailImageDataUrl) return

    const cloudinaryUploadResponse = await (
      await uploadWidgetThumbnailToCloudinary
    ).mutateAsync(thumbnailImageDataUrl)

    await putWidget.mutateAsync({
      widgetId: savedWidgetObject.id as string,
      updates: { cloudinaryThumbnailUrl: cloudinaryUploadResponse.secure_url },
    })

    const shareableHtmlPage = buildShareableHtmlPage(
      savedWidgetObject,
      pictogramBuilder.context.activeLocale,
      cloudinaryUploadResponse
    )

    await uploadWidgetHtmlFileToS3(
      savedWidgetObject.id as string,
      shareableHtmlPage
    )

    pictogramBuilderSend({ type: 'REPLACE_WIDGET', widget: savedWidgetObject })
    navStateSend('SAVE_SUCCESS')
  }

  return (
    <>
      <DialogTitle>Name your visualisation</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Give your widget a name so it can be found easily afterwards. This
          name will not be visible in the visualization on the website.
        </Typography>
        <Grid container>
          <TextField
            fullWidth
            margin="normal"
            label="Visualisation title"
            variant="outlined"
            onChange={onInputChange}
            value={pictogramBuilder.context.widget.internalName}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => navStateSend('CLOSE_SAVE_MODAL')}>Cancel</Button>
        <Button onClick={onWidgetSave} variant="contained" color="primary">
          Create pictogram
        </Button>
      </DialogActions>
    </>
  )
}

function WidgetSaveConfirmation() {
  const [, navStateSend] = useAppNavigationService()
  const [pictogramBuilder, pictogramBuilderSend] = usePictogramBuilderService()
  const successfullySavedPictogramWidget = pictogramBuilder.context.widget

  const inputElementRef = useRef<HTMLInputElement>()

  const onCopyClick = () => {
    inputElementRef.current?.select()
    document.execCommand('copy')
  }

  return (
    <>
      <DialogTitle>Successfully created!</DialogTitle>
      <DialogContent>
        <Stack direction="row" alignItems="center" spacing="20px">
          <span>Visualisation ID</span>:{' '}
          <TextField
            InputLabelProps={{ shrink: false }}
            value={successfullySavedPictogramWidget.id}
            style={{ width: '300px' }}
            size="small"
            inputRef={inputElementRef}
          />
          <IconButton onClick={onCopyClick} size="large">
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </Stack>
        <Paper square elevation={3} sx={{ p: 2, mt: 4, bgcolor: 'lightgrey' }}>
          <Typography variant="h6">
            HOW TO ADD VISUALISATION TO THE WEBSITE
          </Typography>
          <ol>
            <li>Step 1</li>
            <li>Step 2</li>
            <li>Step 3</li>
            <li>Step 4</li>
          </ol>
        </Paper>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button
          variant="contained"
          onClick={() => {
            navStateSend('CANCEL')
            pictogramBuilderSend('RESET')
          }}
        >
          Close
        </Button>
      </DialogActions>
    </>
  )
}
