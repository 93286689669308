import {
  Box,
  Button,
  CardActionArea,
  CardActions,
  CardContent,
  Grid,
  Tab,
  Tabs,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  LOCIZE_ALL_LOCALES,
  WidgetDataType,
} from '@nrcstat-monorepo/config-and-definitions'
import { TabPanel } from '@nrcstat-monorepo/widget-builder/components'
import {
  useAppNavigationService,
  useChartBuilderService,
} from '@nrcstat-monorepo/widget-builder/machines'
import React from 'react'
import { AvailableData } from './AvailableData'
import { CustomData } from './CustomData'

export function DataEntry() {
  const [, navStateSend] = useAppNavigationService()
  const [chartBuilder, chartBuilderSend] = useChartBuilderService()

  const currentTab = chartBuilder.context.widget.dataType

  const onTabChange = (_: unknown, newTab: WidgetDataType) =>
    chartBuilderSend('UPDATE_DATA_TYPE', { dataType: newTab })

  return (
    <Box>
      <Tabs
        value={currentTab}
        onChange={onTabChange}
        indicatorColor="primary"
        sx={{ mb: 2 }}
      >
        <Tab label="Available data" value={WidgetDataType.auto} />
        <Tab label="Custom data" value={WidgetDataType.custom} />
      </Tabs>

      <CardContent>
        <TabPanel value={currentTab} index={WidgetDataType.auto}>
          <AvailableData />
        </TabPanel>
        <TabPanel value={currentTab} index={WidgetDataType.custom}>
          <CustomData />
        </TabPanel>
      </CardContent>
      <CardContent>
        <Grid
          container
          justifyContent="space-between"
          //  sx={{ px: 3 }}
        >
          <Button
            onClick={() => navStateSend('BACK')}
            color="primary"
            variant="outlined"
          >
            Back
          </Button>
          <Button
            onClick={() => navStateSend('NEXT')}
            variant="outlined"
            color="primary"
          >
            Next
          </Button>
        </Grid>
        <Box
          sx={{
            mt: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Button>Reset form</Button>
          <Button onClick={() => navStateSend('CANCEL')}>
            Back to main menu
          </Button>
        </Box>
      </CardContent>
    </Box>
  )
}
