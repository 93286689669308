import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { PictogramWidget } from '@nrcstat-monorepo/config-and-definitions'
import {
  useAppNavigationService,
  usePictogramBuilderService,
} from '@nrcstat-monorepo/widget-builder/machines'
import { useCallback } from 'react'

export function DescribePictogram() {
  const [, navStateSend] = useAppNavigationService()
  const [pictogramBuilder, pictogramBuilderSend] = usePictogramBuilderService()

  // const onInputChange = useCallback(
  //   (field: keyof Omit<PictogramWidget, 'type', 'sections'>) => (event: React.ChangeEvent<HTMLInputElement>) => {

  // })

  const activeLocale = pictogramBuilder.context.activeLocale

  const onInputChange = (
    field: keyof Pick<
      PictogramWidget,
      'title' | 'subtitle' | 'source' | 'socialMediaDescription'
    >
  ) => (event: React.ChangeEvent<HTMLInputElement>) => {
    pictogramBuilderSend({
      type: 'UPDATE_WIDGET_FIELD',
      field,
      value: event.target.value,
    })
  }

  const onEnableSocialMediaChange = () => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log(event)
    pictogramBuilderSend({
      type: 'UPDATE_WIDGET_FIELD',
      field: 'enableSocialMediaSharing',
      value: event.target.checked,
    })
  }

  return (
    <>
      <Typography variant="h5">Basic information</Typography>
      <TextField
        fullWidth
        margin="normal"
        label="Pictogram title"
        placeholder="EDUCATION GENDER INEQUALITY IN YEMEN"
        variant="outlined"
        onChange={onInputChange('title')}
        value={pictogramBuilder.context.widget.title[activeLocale]}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Pictogram subtitle"
        placeholder="Ongoing crisis further increases the gender gap."
        variant="outlined"
        onChange={onInputChange('subtitle')}
        value={pictogramBuilder.context.widget.subtitle[activeLocale]}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Source"
        placeholder="Source: Norwegian Refugee Council, May, 2020"
        variant="outlined"
        onChange={onInputChange('source')}
        value={pictogramBuilder.context.widget.source[activeLocale]}
      />
      <FormControlLabel
        sx={{
          mt: 1,
          mb: 2,
          width: '100%',
          ml: 0,
          '& .MuiFormControlLabel-label': { flexGrow: 1 },
        }}
        control={
          <Switch
            onChange={onEnableSocialMediaChange()}
            checked={pictogramBuilder.context.widget.enableSocialMediaSharing}
          />
        }
        label={
          <Stack>
            <Typography variant="body1">Enable social media sharing</Typography>
            <Typography variant="body2" color="gray">
              Write description for social media platforms
            </Typography>
          </Stack>
        }
        labelPlacement="start"
      />
      {pictogramBuilder.context.widget.enableSocialMediaSharing ? (
        <TextField
          fullWidth
          margin="normal"
          label="Description appearing on social media when sharing"
          placeholder="In Yemen, a country ranked last in the World Economic Forum’s Global Gender Gap index for 13 consecutive years, women have been suffering from deeply entrenched gender inequality rooted in a patriarchal society with rigid gender roles."
          variant="outlined"
          onChange={onInputChange('socialMediaDescription')}
          value={
            pictogramBuilder.context.widget.socialMediaDescription[activeLocale]
          }
        />
      ) : null}
    </>
  )
}
