export const GlobalDisplacementDataPointId = {
  totalRefugeesFromX: 'totalRefugeesFromX',
  refugeesInXFromOtherCountriesInYear: 'refugeesInXFromOtherCountriesInYear',
  idpsInXInYear: 'idpsInXInYear',
  newRefugeesFromXInYear: 'newRefugeesFromXInYear',
  newRefugeesInXFromOtherCountriesInYear:
    'newRefugeesInXFromOtherCountriesInYear',
  newIdpsInXInYear: 'newIdpsInXInYear',
  percentageWomenFleeingToCountry: 'percentageWomenFleeingToCountry',
  percentageChildrenFleeingToCountry: 'percentageChildrenFleeingToCountry',
  voluntaryReturnsToXInYear: 'voluntaryReturnsToXInYear',
  asylumSeekersFromXToNorwayInYear: 'asylumSeekersFromXToNorwayInYear',
} as const

export type GlobalDisplacementDataPointId = typeof GlobalDisplacementDataPointId[keyof typeof GlobalDisplacementDataPointId]
