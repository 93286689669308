import {
  DATA_API_URL,
  WidgetBase,
} from '@nrcstat-monorepo/config-and-definitions'
import { apiFetch } from './utils/api-fetch'

export async function fetchWidgets<Widget extends WidgetBase>(
  query?: string
): Promise<Widget[]> {
  const queryString = query ?? ''

  const filters = JSON.stringify({
    where: { internalName: { like: queryString, options: 'i' } },
  })

  const widgets = await apiFetch<Widget[]>(
    `${DATA_API_URL}/widgets?filter=${filters}`
  )

  const sorted = widgets.sort(byDateSorter)

  return sorted
}

function byDateSorter<Widget extends WidgetBase>(
  widgetA: Widget,
  widgetB: Widget
): number {
  return (
    new Date(String(widgetB.dateCreated)).getTime() -
    new Date(String(widgetA.dateCreated)).getTime()
  )
}
