import {
  CountryDefinition,
  DATA_API_URL,
} from '@nrcstat-monorepo/config-and-definitions'
import { apiFetch } from './utils/api-fetch'

export async function fetchCountries() {
  const data = await apiFetch<CountryDefinition[]>(
    `${DATA_API_URL}/countries?filter=${encodeURIComponent(
      JSON.stringify({
        where: {
          regionCodeNRC: { nin: ['MISC_AND_STATELESS'] },
          idContinent: { nin: ['WORLD'] },
        },
        order: 'name',
      })
    )}`
  )

  const countries = data.filter((c) => c.idContinent)
  return countries
}
