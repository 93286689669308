export const LocizeLocale = {
  'en-GB': 'en-GB',
  'nb-NO': 'nb-NO',
  'de-DE': 'de-DE',
  'sv-SE': 'sv-SE',
} as const

export type LocizeLocale = typeof LocizeLocale[keyof typeof LocizeLocale]

export const LOCIZE_ALL_LOCALES: Array<LocizeLocale> = Object.values(
  LocizeLocale
)
