import { LocizeLocale } from '../locize-locale'
import { WidgetBase } from './widget-base'

export interface PictogramSectionIcon {
  uniqKey: string
  icon?: Icon
  data: number
  dataColor: DataColor
}

export interface PictogramSection {
  uniqKey: string
  title: Partial<Record<LocizeLocale, string>>
  icons: Array<PictogramSectionIcon>
}

export interface PictogramWidget extends WidgetBase {
  type: 'pictogram'
  subtitle: Partial<Record<LocizeLocale, string>>
  source: Partial<Record<LocizeLocale, string>>
  backgroundColor: BackgroundColorKey
  sections: Array<PictogramSection>
}

export const Icon = {
  Camp: 'Camp',
  Education: 'Education',
  Food: 'Food',
  Legal: 'Legal',
  Shelter: 'Shelter',
  WASH: 'WASH',
  PeopleChildren: 'PeopleChildren',
  PeopleFemale: 'PeopleFemale',
  PeopleLittleBoy: 'PeopleLittleBoy',
  PeopleLittleGirl: 'PeopleLittleGirl',
  PeopleMale: 'PeopleMale',
  PeopleRefugeeFamily: 'PeopleRefugeeFamily',
  PeopleRefugeeFamilyAlt: 'PeopleRefugeeFamilyAlt',
  PeopleRefugeesRunning: 'PeopleRefugeesRunning',
  PeopleSchoolBoy: 'PeopleSchoolBoy',
  PeopleSchoolGirl: 'PeopleSchoolGirl',
  PeopleChildrenCircle: 'PeopleChildrenCircle',
  PeopleFemaleCircle: 'PeopleFemaleCircle',
  PeopleLittleBoyCircle: 'PeopleLittleBoyCircle',
  PeopleLittleGirlCircle: 'PeopleLittleGirlCircle',
  PeopleMaleCircle: 'PeopleMaleCircle',
  PeopleRefugeeFamilyCircle: 'PeopleRefugeeFamilyCircle',
  PeopleRefugeeFamilyAltCircle: 'PeopleRefugeeFamilyAltCircle',
  PeopleRefugeesRunningCircle: 'PeopleRefugeesRunningCircle',
  PeopleSchoolBoyCircle: 'PeopleSchoolBoyCircle',
  PeopleSchoolGirlCircle: 'PeopleSchoolGirlCircle',
} as const
export type Icon = typeof Icon[keyof typeof Icon]

export const BackgroundColorKey = {
  White: 'white',
  LightGray: 'light-grey',
  DarkGray: 'dark-grey',
} as const
export type BackgroundColorKey = typeof BackgroundColorKey[keyof typeof BackgroundColorKey]

export const DataColor = {
  Orange: '#FD5A00',
  Blue: '#007D99',
  Yellow: '#FDC82F',
  Red: '#FF2B00',
} as const
export type DataColor = typeof DataColor[keyof typeof DataColor]

export const TextColors = {
  LightestGray: '#C2C2C2',
  LightGray: '#707070',
  DarkGray: '#474747',
} as const
export type TextColors = typeof TextColors[keyof typeof TextColors]

export const IconBaseColor = {
  LightGrey: '#c2c2c2',
  DarkGrey: '#ededed',
} as const
export type IconBaseColor = typeof IconBaseColor[keyof typeof IconBaseColor]

export const BackgroundColorToIconBaseColorMap = {
  [BackgroundColorKey.White]: IconBaseColor.LightGrey,
  [BackgroundColorKey.White]: IconBaseColor.DarkGrey,
  [BackgroundColorKey.White]: IconBaseColor.LightGrey,
} as const
export type BackgroundColorToIconBaseColorMap = typeof BackgroundColorToIconBaseColorMap[keyof typeof BackgroundColorToIconBaseColorMap]

export function isWidgetPictogramWidget(
  widget?: WidgetBase
): widget is PictogramWidget {
  return widget?.type === 'pictogram'
}
