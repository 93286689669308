import {
  LocizeLocale,
  RAZZLE_API_URL,
  WidgetBase,
} from '@nrcstat-monorepo/config-and-definitions'
import domToImage from 'dom-to-image'
import { useMutation } from 'react-query'

export function renderWidget(
  targetElementSelector: string,
  debouncedWidgetObject: WidgetBase,
  locale: LocizeLocale
) {
  // Special case, pay attention:
  // The Razzle server is charged with widget rendering after the below request is made.
  // Since Razzle provides ALL JS bundles etc. needed for rendering, it will provide its
  // own copy of the react and react-dom packages. Every time this function is called,
  // yet another bundle is provided from Razzle. We therefore quickly end up with multiple
  // copies of React... all trying to render a <Widget> to the same targetElementSelector
  // element. This caused a lot of issues and headaches, with a "every second call to this
  // functions caused rerender to fail" issue. According to React docs & best practices,
  // React does play well with having multiple versions of itself running in the same
  // page... but things get messy when they're all "competing" for the same elements.
  //
  // The fix?
  // Have Razzle inject the ReactDOM it used to render <Widget> into window.razzleReactDOM.
  // Then we use that ReactDOM to **unmount the <Widget>** BEFORE we proceed with the "next"
  // ReactDOM from razzle rendering the next one. Problem solved!
  // More detail: https://www.notion.so/binarylights/NrcStat-and-Share-Feature-d2db4bf5bced464b84e99f7cbc0c1642
  ;(window as any).razzleReactDOM?.unmountComponentAtNode(
    document.querySelector(targetElementSelector)
  )

  const windowRef = window as any
  const documentRef = document as any

  const params = {
    targetSelector: targetElementSelector,
    widgetId: `${locale}-widget-wizard`,
    widgetObject: debouncedWidgetObject,
  }

  const queueSerialized = encodeURIComponent(JSON.stringify([params]))
  const assetsPath = `${RAZZLE_API_URL}/render-widgets?queue=${queueSerialized}`

  fetch(assetsPath)
    .then((resp) => resp.json())
    .then((data) => {
      windowRef.localeTranslation = data.localeTranslation
      windowRef.nrcStatDrawWidgetQueue = data.widgetQueue

      const loadableInfoScriptEl = document.createElement('script')
      loadableInfoScriptEl.id = '__LOADABLE_REQUIRED_CHUNKS__'
      loadableInfoScriptEl.type = 'application/json'
      loadableInfoScriptEl.appendChild(
        document.createTextNode(
          JSON.stringify(data.__LOADABLE_REQUIRED_CHUNKS__)
        )
      )
      documentRef.querySelector('body').appendChild(loadableInfoScriptEl)
      data.scripts.forEach((script: any) => {
        const scriptEl = documentRef.createElement('script') as any
        scriptEl.src = script.src
        scriptEl.dataChunk = script['data-chunk']
        scriptEl.async = true
        documentRef.querySelector('body').appendChild(scriptEl)
      })

      data.links.forEach((link: any) => {
        const linkEl = document.createElement('link')
        linkEl.href = link
        linkEl.rel = 'stylesheet'
        documentRef.querySelector('body').appendChild(linkEl)
      })
    })
}

// Heavily inspired by: https://www.oodlestechnologies.com/blogs/converting-any-html-dom-node-to-an-svg-png-or-jpg-image/
export function useRenderWidgetThumbnailBlob() {
  return useMutation(async function doIt(
    widgetContainerElementRef: React.MutableRefObject<
      HTMLDivElement | null | undefined
    >
  ) {
    if (!widgetContainerElementRef.current) return

    // We don't want the share button to be captured in the thumbnail; hide it before we proceed
    const shareButtonElement: HTMLDivElement | null = widgetContainerElementRef.current.querySelector(
      '.share-button-wrapper'
    )
    if (shareButtonElement) {
      shareButtonElement.style.visibility = 'hidden'
    }

    const pictogramContainer = widgetContainerElementRef.current.querySelector(
      '.container'
    ) as HTMLDivElement | null
    if (!pictogramContainer) return

    return domToImage
      .toPng(pictogramContainer, {
        bgcolor: 'white',
        style: {
          paddingTop: '0',
          marginTop: '0',
          paddingBottom: '0',
          marginBottom: '0',
        },
      })
      .then((blob) => {
        if (shareButtonElement) {
          shareButtonElement.style.visibility = 'visible'
        }
        return blob
      })
  })
}

export interface CloudinaryUploadResponse {
  asset_id: string
  public_id: string
  version: number
  version_id: string
  signature: string
  width: number
  height: number
  format: string
  resource_type: string
  created_at: string
  bytes: number
  type: string
  etag: string
  placeholder: boolean
  url: string
  secure_url: string
  access_mode: string
}

export async function useMutationUploadWidgetThumbnailToCloudinary() {
  return useMutation((dataUrl: string) => {
    const formData = new FormData()
    formData.append('upload_preset', 'dks4kymt')
    formData.append('file', dataUrl)

    return fetch('https://api.cloudinary.com/v1_1/dgpthys9y/image/upload', {
      method: 'POST',
      body: formData,
    }).then((resp) => resp.json() as Promise<CloudinaryUploadResponse>)
  })
}

export function buildShareableHtmlPage(
  widget: WidgetBase,
  locale: LocizeLocale,
  { secure_url: url, width, height }: CloudinaryUploadResponse
) {
  const urlThumbnailImageForSocialMedia = url.replace(
    '/upload/',
    '/upload/e_trim/bo_25px_solid_white/c_lpad,ar_1.91/'
  )
  const urlNeatlyTrimmedAndBorderedImage = url.replace(
    '/upload/',
    '/upload/e_trim/bo_10px_solid_white/'
  )
  return `
    <html>
      <head>
        <meta name="twitter:card" content="summary_large_image">
        <meta name="twitter:site" content="@flyktninghjelp">

        <meta property="twitter:title" content="${widget?.title[locale] ?? ''}">
        <meta property="twitter:description" content="${
          widget?.socialMediaDescription[locale] ?? ''
        }">
        <meta property="twitter:image" content="${urlThumbnailImageForSocialMedia}">
        
        <meta property="og:url" content="https://share.nrcdata.no/${
          widget.id
        }.html">

        <meta property="og:title" content="${widget?.title[locale] ?? ''}">
      
        <meta property="og:description" content="${
          widget?.socialMediaDescription[locale] ?? ''
        }">

        <meta property="og:image" content="${urlThumbnailImageForSocialMedia}">
        
        <meta property="og:image:width" content="${width}">
        <meta property="og:image:height" content="${height}">

        <meta property="og:site_name" content="Norwegian Refugee Council">
        <meta property="og:type" content="website">

        <script>
          const urlSearchParams = new URLSearchParams(window.location.search);
          const sharedFromUrl = urlSearchParams.get('sharedFromUrl');
          if (sharedFromUrl) window.location.href = sharedFromUrl;
        </script>

      </head>
      <body style="margin: 0; padding: 0;">
        <img src="${urlNeatlyTrimmedAndBorderedImage}">
      </body>
    </html>`
}

export function uploadWidgetHtmlFileToS3Bucket() {
  const getSignedUploadUrl = useMutationGetSignedUploadUrl()
  const uploadHtmlFile = useMutationUploadHtmlFile()

  return async function (widgetId: string, htmlFileBody: string) {
    const { signedUrl } = await getSignedUploadUrl.mutateAsync(widgetId)

    await uploadHtmlFile.mutateAsync({
      signedUrl,
      htmlFileBody,
    })
  }
}

function useMutationGetSignedUploadUrl() {
  return useMutation((widgetId: string) =>
    fetch(
      `https://j7obh3fvcieaebg7eechd6v36m0qhyup.lambda-url.eu-central-1.on.aws/`,
      {
        method: 'POST',
        body: JSON.stringify({
          widgetId,
        }),
        headers: {
          'Content-Type': 'application/application/json',
        },
      }
    ).then((resp) => resp.json() as Promise<{ signedUrl: string }>)
  )
}

function useMutationUploadHtmlFile() {
  return useMutation(
    ({
      signedUrl,
      htmlFileBody,
    }: {
      signedUrl: string
      htmlFileBody: string
    }) =>
      fetch(signedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'text/html; charset=utf-8',
        },
        body: htmlFileBody,
      }) as Promise<unknown>
  )
}
