import {
  fetchLocizeLocalesNamespaces,
  LocizeLocaleSet,
} from '@nrcstat-monorepo/api'
import { LOCIZE_NAMESPACES_CACHE_TIME } from '@nrcstat-monorepo/config-and-definitions'
import { useQuery } from 'react-query'

export function useLocizeNamespaces(locales: string[], namespaces: string[]) {
  return useQuery<LocizeLocaleSet, Error>(
    ['allLocizeNamespaces', locales, namespaces],
    () => fetchLocizeLocalesNamespaces(locales, namespaces),
    {
      // cacheTime: LOCIZE_NAMESPACES_CACHE_TIME,
      staleTime: LOCIZE_NAMESPACES_CACHE_TIME,
    }
  )
}
