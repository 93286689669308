import { createClient } from '@sanity/client'
import i18n from 'i18next'
import { useEffect } from 'react'
import { initReactI18next, useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

const sanityClient = createClient({
  projectId: 'obdzv40s',
  dataset: 'production',
  useCdn: true,
  apiVersion: '2023-03-01',
})

function useAllSanityKeys() {
  const groqQuery = `
  *[_type=="key"]{
    _id,
    id,
    value,
    namespace->
  }`
  return useQuery<SanityKey[]>('allSanityKeys', () =>
    sanityClient.fetch(groqQuery)
  )
}

function fromSanityKeysToI18nResources(keys: SanityKey[]) {
  let localeKeys = keys
  localeKeys = localeKeys.map((key) => {
    delete key.value._type
    for (const locale in key.value) {
      const correctLocale =
        locale.substring(0, 2) + '-' + locale.substring(2, 4)
      key.value[correctLocale] = key.value[locale]
      delete key.value[locale]
    }
    return key
  })

  const localeNamespaceKeysMap: Record<
    string,
    Record<string, Record<string, string>>
  > = {}
  for (const key of localeKeys) {
    for (const [locale, value] of Object.entries(key.value)) {
      if (!localeNamespaceKeysMap[locale]) localeNamespaceKeysMap[locale] = {}
      if (!localeNamespaceKeysMap[locale][key.namespace.name])
        localeNamespaceKeysMap[locale][key.namespace.name] = {}
      localeNamespaceKeysMap[locale][key.namespace.name][key.id] = value
    }
  }

  return localeNamespaceKeysMap
}

;(i18n as any)
  .use(initReactI18next) // if not using I18nextProvider
  .init({
    fallbackLng: 'en-GB',
    defaultNS: 'Glossary',
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    // react i18next special options (optional)
    react: {
      wait: false,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
    },
    keySeparator: false,

    resources: {},
  })

interface SanityKey {
  id: string
  namespace: {
    name: string
  }
  value: Record<string, string>
}

export function useI18n() {
  const sanityKeysQuery = useAllSanityKeys()

  useEffect(() => {
    if (sanityKeysQuery.isSuccess) {
      const localeNamespaceKeysMap = fromSanityKeysToI18nResources(
        sanityKeysQuery.data
      )

      for (const locale in localeNamespaceKeysMap) {
        for (const namespaceId in localeNamespaceKeysMap[locale]) {
          const namespaceResource = localeNamespaceKeysMap[locale][namespaceId]
          i18n.addResourceBundle(locale, namespaceId, namespaceResource)
        }
      }
    }
  })

  const { t } = useTranslation()

  return t
}
