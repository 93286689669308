import makeStyles from '@mui/styles/makeStyles'
import { useAppNavigationService } from '@nrcstat-monorepo/widget-builder/machines'
import { Home } from './Home'
import { Templates } from './Templates'
import { WidgetInfoActionModal } from './WidgetInfoActionModal'

const useStyles = makeStyles({
  grid: {
    marginTop: 68,
  },
  card: {
    maxWidth: 340,
  },
  cardImage: {
    height: 216,
  },
  cardTypography: {
    textTransform: 'uppercase',
    textAlign: 'center',
  },
})

export function Main() {
  const [navState] = useAppNavigationService()

  return (
    <>
      {navState.matches('loggedIn.main.pages.home') && <Home />}
      {navState.matches('loggedIn.main.pages.templates') && <Templates />}
      <WidgetInfoActionModal />
    </>
  )
}
