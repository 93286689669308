import { isObject } from 'lodash'

interface NestedObject {
  [key: string]: string | NestedObject
}

export function mapNestedObjectToPathKeyedObject(object: NestedObject) {
  const resultObject: Record<string, string> = {}
  const sub = (
    obj: NestedObject,
    parentPath: string | undefined = undefined
  ) => {
    for (const key in obj) {
      const path = (parentPath ? `${parentPath}.` : '') + key
      const value = obj[key]
      if (isObject(value)) sub(value, path)
      else resultObject[path] = value
    }
  }
  sub(object)
  return resultObject
}
