import {
  DATA_API_URL,
  WidgetBase,
} from '@nrcstat-monorepo/config-and-definitions'
import { apiFetch } from './utils/api-fetch'

export function fetchWidget<Widget extends WidgetBase>(widgetId: string) {
  const doFetch = async (): Promise<Widget> => {
    const widget = await apiFetch<Widget>(`${DATA_API_URL}/widgets/${widgetId}`)

    return widget
  }

  return doFetch
}
