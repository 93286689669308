/* eslint-disable @typescript-eslint/no-empty-interface */
import { useMachine } from '@xstate/react'
import { createContext, useContext, useEffect } from 'react'
import { assign, createMachine } from 'xstate'

interface Context {
  selectedWidgetId: string | null
}

export type AppNavigationMachineEvent =
  | { type: 'LOGIN_SUCCESS' }
  | { type: 'LOGOUT' }
  | { type: 'OPEN_WIDGET_MODAL'; widgetId: string }
  | { type: 'BUILD_CHART' }
  | { type: 'BUILD_PICTOGRAM' }
  | { type: 'BUILD_TIMELINE' }
  | { type: 'VIEW_TEMPLATES' }
  | { type: 'CLOSE_WIDGET_MODAL' }
  | { type: 'PICK_TAB_AVAILABLE_DATA' }
  | { type: 'PICK_TAB_CUSTOM_DATA' }
  | { type: 'PREPARE_WIDGET_SAVE' }
  | { type: 'CLOSE_SAVE_MODAL' }
  | { type: 'SAVE_SUCCESS' }
  | { type: 'NEXT' }
  | { type: 'BACK' }
  | { type: 'CANCEL' }
  | { type: 'PREPARE_COUNTRY_SIDEBAR_ID' }

const initialContext: Context = { selectedWidgetId: null }

const mainState = {
  on: {
    BUILD_CHART: 'buildChart',
    BUILD_PICTOGRAM: 'buildPictogram',
    BUILD_TIMELINE: 'buildTimeline',
    PREPARE_COUNTRY_SIDEBAR_ID: 'prepareCountrySidebarId',
  },
  type: 'parallel' as const,
  initial: 'home',
  states: {
    pages: {
      initial: 'home',
      states: {
        home: {
          on: {
            VIEW_TEMPLATES: 'templates',
          },
        },
        templates: {
          on: {
            BACK: 'home',
          },
        },
      },
    },
    widgetInfoActionModal: {
      initial: 'closed',
      states: {
        closed: {
          on: {
            OPEN_WIDGET_MODAL: {
              target: 'open',
              actions: 'setSelectedWidgetId',
            },
          },
        },
        open: {
          on: {
            CLOSE_WIDGET_MODAL: 'closed',
            // TODO: I would have liked to see a thing like
            // action: 'unsetSelectedWidgetId'. I did try that,
            // but lost something like two hours fighting TS.
          },
        },
      },
    },
  },
}

const buildChartState = {
  on: {
    BACK: 'main',
    CANCEL: 'main',
  },
  type: 'parallel' as const,
  states: {
    steps: {
      initial: 'dataEntry',
      states: {
        dataEntry: {
          initial: 'customData',
          on: {
            NEXT: 'pickWidgetType',
            PICK_TAB_AVAILABLE_DATA: '.availableData',
            PICK_TAB_CUSTOM_DATA: '.customData',
          },
          states: {
            availableData: {},
            customData: {},
          },
          meta: {
            stepIndex: 0,
          },
        },
        pickWidgetType: {
          on: {
            BACK: 'dataEntry',
            NEXT: 'describeWidget',
          },
          meta: {
            stepIndex: 1,
          },
        },
        describeWidget: {
          on: {
            BACK: 'pickWidgetType',
          },
          meta: {
            stepIndex: 2,
          },
        },
      },
    },
    saveModal: {
      initial: 'closed',
      on: {
        PREPARE_WIDGET_SAVE: '.specifyInternalName',
      },
      states: {
        closed: {},
        specifyInternalName: {
          on: {
            CLOSE_SAVE_MODAL: 'closed',
            SAVE_SUCCESS: 'widgetSavedConfirmation',
          },
        },
        widgetSavedConfirmation: {
          on: {
            CLOSE_SAVE_MODAL: 'closed',
          },
        },
      },
    },
  },
}

const buildPictogramStates = {
  on: {
    BACK: 'main',
    CANCEL: 'main',
  },
  type: 'parallel' as const,
  states: {
    steps: {
      initial: 'describePictogram',
      states: {
        describePictogram: {
          on: {
            NEXT: 'defineSections',
          },
        },
        defineSections: {
          on: {
            BACK: 'describePictogram',
          },
        },
      },
    },
    saveModal: {
      initial: 'closed',
      on: {
        PREPARE_WIDGET_SAVE: '.specifyInternalName',
      },
      states: {
        closed: {},
        specifyInternalName: {
          on: {
            CLOSE_SAVE_MODAL: 'closed',
            SAVE_SUCCESS: 'widgetSavedConfirmation',
          },
        },
        widgetSavedConfirmation: {
          on: {
            CLOSE_SAVE_MODAL: 'closed',
          },
        },
      },
    },
  },
}

const buildTimelineStates = {
  on: {
    CANCEL: 'main',
  },
  type: 'parallel' as const,
  states: {
    form: {
      initial: 'dataEntry',
      states: {
        dataEntry: {},
      },
    },
    saveModal: {
      initial: 'closed',
      on: {
        PREPARE_WIDGET_SAVE: '.specifyInternalName',
      },
      states: {
        closed: {},
        specifyInternalName: {
          on: {
            CLOSE_SAVE_MODAL: 'closed',
            SAVE_SUCCESS: 'widgetSavedConfirmation',
          },
        },
        widgetSavedConfirmation: {
          on: {
            CLOSE_SAVE_MODAL: 'closed',
          },
        },
      },
    },
  },
}

const prepareCountrySidebarIdStates = {
  on: {
    BACK: 'main',
  },
}

const loggedOutStates = {
  initial: 'loginForm',
  states: {
    loginForm: {},
  },
}

export const appNavigationMachine = createMachine(
  {
    tsTypes: {} as import('./app-navigation-machine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as AppNavigationMachineEvent,
    },
    id: 'appNavigationMachine',
    initial: 'authStateLoading',
    context: initialContext,
    on: {
      LOGIN_SUCCESS: 'loggedIn',
      LOGOUT: 'loggedOut',
    },
    states: {
      authStateLoading: {},
      loggedIn: {
        initial: 'main',
        states: {
          main: { ...mainState },
          buildChart: { ...buildChartState },
          buildPictogram: { ...buildPictogramStates },
          buildTimeline: { ...buildTimelineStates },
          prepareCountrySidebarId: { ...prepareCountrySidebarIdStates },
        },
      },
      loggedOut: {
        ...loggedOutStates,
      },
    },
  },
  {
    actions: {
      setSelectedWidgetId: assign({
        selectedWidgetId: (_ctx, event) => event.widgetId,
      }),
    },
  }
)

export function useAppNavigationMachine() {
  return useMachine(appNavigationMachine, { devTools: true })
}

type UseAppNavigationMachineTuple = ReturnType<typeof useAppNavigationMachine>

export const AppNavigationServiceContext = createContext<UseAppNavigationMachineTuple | null>(
  null
)

export function useAppNavigationService() {
  const appNavigationService = useContext<null | UseAppNavigationMachineTuple>(
    AppNavigationServiceContext
  ) as UseAppNavigationMachineTuple

  return appNavigationService
}
