import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  LocizeLocale,
  LOCIZE_ALL_LOCALES,
  TimelineEntry,
} from '@nrcstat-monorepo/config-and-definitions'
import { TabPanel } from '@nrcstat-monorepo/widget-builder/components'
import { useTimelineBuilderService } from '@nrcstat-monorepo/widget-builder/machines'
import React, { useCallback } from 'react'
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from '@mui/icons-material'
import ReactMarkdown from 'react-markdown'
import { useI18n } from '@nrcstat-monorepo/i18n'

export function TimelineEntriesForm() {
  const [timelineBuilder, timelineBuilderSend] = useTimelineBuilderService()

  const {
    activeLocale,
    widget: { entries },
  } = timelineBuilder.context

  const currentTab = activeLocale

  const onInputChange = (
    field: keyof Omit<TimelineEntry, 'uniqKey'>,
    uniqKey: string
  ) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const entry = entries.find((entry) => entry.uniqKey === uniqKey)
    if (!entry) return
    const value = event.target.value
    timelineBuilderSend({
      type: 'ENTRY_UPDATE',
      uniqKey,
      field,
      value,
    })
  }

  const t = useI18n()

  return (
    <div>
      {LOCIZE_ALL_LOCALES.map((locale) => (
        <TabPanel key={locale} value={currentTab} index={locale}>
          {entries.map((entry, index) => (
            <Accordion key={entry.uniqKey} disableGutters sx={{ mb: 2 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ '& .MuiAccordionSummary-content': { my: 0 } }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    ml: 1,
                  }}
                >
                  <Typography variant="body1" sx={{ flexGrow: 1 }}>
                    {entry?.title[activeLocale]
                      ? entry.title[activeLocale]
                      : 'New event'}
                  </Typography>
                  <ButtonGroup
                    color="primary"
                    orientation="vertical"
                    variant="text"
                  >
                    <IconButton
                      size="small"
                      disabled={entries.length < 2 || index === 0}
                      onClick={(e) => {
                        e.stopPropagation()
                        timelineBuilderSend({
                          type: 'ENTRY_REORDER',
                          direction: 'up',
                          index: index,
                        })
                      }}
                      sx={{ p: 0 }}
                    >
                      <ArrowDropUpIcon sx={{ color: 'white' }} />
                    </IconButton>
                    <IconButton
                      size="small"
                      disabled={
                        entries.length < 2 || index + 1 === entries.length
                      }
                      onClick={(e) => {
                        e.stopPropagation()
                        timelineBuilderSend({
                          type: 'ENTRY_REORDER',
                          direction: 'down',
                          index: index,
                        })
                      }}
                      sx={{ p: 0 }}
                    >
                      <ArrowDropDownIcon sx={{ color: 'white' }} />
                    </IconButton>
                  </ButtonGroup>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Date"
                    variant="outlined"
                    // helperText="Follow our style guide for date and time. Valid examples: 21 July 2016, July 2016, 2016."
                    helperText={
                      <ReactMarkdown
                        children={t(
                          `VisualisationBuilder.UserInterfaceText:timelineBuilder.dateFormatHelperText`
                        )}
                        components={{
                          p: ({ children }) => <span>{children}</span>,
                        }}
                      />
                    }
                    onChange={onInputChange('title', entry.uniqKey)}
                    value={entry.title[activeLocale]}
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Title"
                    variant="outlined"
                    onChange={onInputChange('subtitle', entry.uniqKey)}
                    value={entry.subtitle[activeLocale]}
                  />
                  <TextField
                    fullWidth
                    margin="normal"
                    multiline
                    rows={5}
                    label="Body text"
                    helperText="Max 600 characters"
                    variant="outlined"
                    inputProps={{ maxLength: 600 }}
                    onChange={onInputChange('body', entry.uniqKey)}
                    value={entry.body[activeLocale]}
                  />
                  <Grid container justifyContent="flex-end">
                    <Button
                      startIcon={<DeleteIcon />}
                      onClick={() =>
                        timelineBuilderSend({
                          type: 'ENTRY_REMOVE',
                          uniqKey: entry.uniqKey,
                        })
                      }
                    >
                      Delete event
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
          <Grid container justifyContent="center">
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => timelineBuilderSend('ENTRY_ADD')}
            >
              Add event
            </Button>
          </Grid>
        </TabPanel>
      ))}
    </div>
  )
}
