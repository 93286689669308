import {
  Box,
  Button,
  CardContent,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import {
  ChartWidget,
  LocizeLocale,
  LOCIZE_ALL_LOCALES,
} from '@nrcstat-monorepo/config-and-definitions'
import { TabPanel } from '@nrcstat-monorepo/widget-builder/components'
import {
  useAppNavigationService,
  useChartBuilderService,
} from '@nrcstat-monorepo/widget-builder/machines'
import { useRef } from 'react'
import { useWidgetPreview } from '../../hooks/use-widget-preview'
import { SaveChartModal } from './SaveChartModal'

export function DescribeWidget() {
  const [, navStateSend] = useAppNavigationService()
  const [chartBuilder, chartBuilderSend] = useChartBuilderService()
  const context = chartBuilder.context
  const activeLocale = context.activeLocale

  const widgetContainerElementRef = useRef<HTMLDivElement | null>()
  useWidgetPreview('#widget-container', context.widget, activeLocale)

  const onTabChange = (_: unknown, newTab: LocizeLocale) => {
    chartBuilderSend({ type: 'CHANGE_ACTIVE_LOCALE', newLocale: newTab })
  }

  const onInputChange = (
    field:
      | keyof Pick<
          ChartWidget,
          'title' | 'socialMediaDescription' | 'overridingColourScheme'
        >
      | keyof Pick<
          ChartWidget['config'],
          'subtitle' | 'source' | 'linkToSource'
        >
  ) => (event: React.ChangeEvent<HTMLInputElement>) => {
    chartBuilderSend({
      type: 'UPDATE_WIDGET_FIELD',
      field,
      value: event.target.value,
    })
  }
  const onToggleChange = (
    field: keyof Pick<
      ChartWidget,
      'enableSocialMediaSharing' | 'enablePopup' | 'enableColourSchemeOverride'
    >
  ) => (event: React.ChangeEvent<HTMLInputElement>) =>
    chartBuilderSend({
      type: 'UPDATE_WIDGET_FIELD',
      field: field,
      value: event.target.checked,
    })
  const onEnableSocialMediaChange = () => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    chartBuilderSend({
      type: 'UPDATE_WIDGET_FIELD',
      field: 'enableSocialMediaSharing',
      value: event.target.checked,
    })
  }
  const onEnablePopupChange = () => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    chartBuilderSend({
      type: 'UPDATE_WIDGET_FIELD',
      field: 'enablePopup',
      value: event.target.checked,
    })
  }

  return (
    <div>
      <Tabs value={activeLocale} onChange={onTabChange}>
        {LOCIZE_ALL_LOCALES.map((locale) => (
          <Tab key={locale} label={locale.toUpperCase()} value={locale} />
        ))}
      </Tabs>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <div
              id="widget-container"
              ref={(ref) => (widgetContainerElementRef.current = ref)}
            />
          </Grid>
          <Grid item xs={6}>
            {LOCIZE_ALL_LOCALES.map((locale) => (
              <TabPanel key={locale} value={activeLocale} index={locale}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Visualization title"
                  placeholder=""
                  variant="outlined"
                  onChange={onInputChange('title')}
                  value={context.widget.title[activeLocale]}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="Visualization subtitle"
                  placeholder=""
                  variant="outlined"
                  onChange={onInputChange('subtitle')}
                  value={context.widget.config.subtitle[activeLocale]}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="Source"
                  placeholder=""
                  variant="outlined"
                  onChange={onInputChange('source')}
                  value={context.widget.config.source[activeLocale]}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="(optional) Link to the data source"
                  placeholder="https://www.unhcr.org/dataset-001"
                  variant="outlined"
                  onChange={onInputChange('linkToSource')}
                  value={context.widget.config.linkToSource}
                />
                {context.widget.type !== 'table' ? (
                  <>
                    <FormControlLabel
                      sx={{
                        mt: 1,
                        width: '100%',
                        ml: 0,
                        '& .MuiFormControlLabel-label': { flexGrow: 1 },
                      }}
                      control={
                        <Switch
                          onChange={onToggleChange('enablePopup')}
                          checked={context.widget.enablePopup}
                        />
                      }
                      label={
                        <Stack>
                          <Typography variant="body1">Show popup</Typography>
                          <Typography variant="body2" color="gray">
                            Displays exact data when hovering part of the chart
                          </Typography>
                        </Stack>
                      }
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      sx={{
                        mt: 1,
                        width: '100%',
                        ml: 0,
                        '& .MuiFormControlLabel-label': { flexGrow: 1 },
                      }}
                      control={
                        <Switch
                          onChange={onToggleChange('enableSocialMediaSharing')}
                          checked={context.widget.enableSocialMediaSharing}
                        />
                      }
                      label={
                        <Stack>
                          <Typography variant="body1">
                            Enable social media sharing
                          </Typography>
                          <Typography variant="body2" color="gray">
                            Write description for social media platforms
                          </Typography>
                        </Stack>
                      }
                      labelPlacement="start"
                    />
                    {context.widget.enableSocialMediaSharing ? (
                      <TextField
                        fullWidth
                        margin="normal"
                        label="Description appearing on social media when sharing"
                        placeholder="In Yemen, a country ranked last in the World Economic Forum’s Global Gender Gap index for 13 consecutive years, women have been suffering from deeply entrenched gender inequality rooted in a patriarchal society with rigid gender roles."
                        variant="outlined"
                        onChange={onInputChange('socialMediaDescription')}
                        value={
                          context.widget.socialMediaDescription[activeLocale]
                        }
                      />
                    ) : null}
                    <FormControlLabel
                      sx={{
                        mt: 1,
                        width: '100%',
                        ml: 0,
                        '& .MuiFormControlLabel-label': { flexGrow: 1 },
                      }}
                      control={
                        <Switch
                          onChange={onToggleChange(
                            'enableColourSchemeOverride'
                          )}
                          checked={context.widget.enableColourSchemeOverride}
                        />
                      }
                      label={
                        <Stack>
                          <Typography variant="body1">
                            Enable Lars Hack override mode?
                          </Typography>
                          <Typography variant="body2" color="gray">
                            Do not click this button.
                          </Typography>
                        </Stack>
                      }
                      labelPlacement="start"
                    />
                    {context.widget.enableColourSchemeOverride ? (
                      <TextField
                        fullWidth
                        margin="normal"
                        label="Colour scheme override (comma-separated list of hex codes)"
                        placeholder="#aaa,#bbb,#111,#fafafa, etc... enter the secret nuclear hex codes here"
                        variant="outlined"
                        onChange={onInputChange('overridingColourScheme')}
                        value={context.widget.overridingColourScheme}
                      />
                    ) : null}
                  </>
                ) : null}
              </TabPanel>
            ))}
          </Grid>
        </Grid>
      </CardContent>
      <CardContent sx={{ mt: 3 }}>
        <Grid container justifyContent="space-between">
          <Button
            onClick={() => navStateSend('BACK')}
            color="primary"
            variant="outlined"
          >
            Back
          </Button>
          <Button
            onClick={() => navStateSend('PREPARE_WIDGET_SAVE')}
            variant="contained"
            color="primary"
          >
            Save and finish
          </Button>
        </Grid>
        <Box
          sx={{
            mt: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Button>Reset form</Button>
          <Button onClick={() => navStateSend('CANCEL')}>
            Back to main menu
          </Button>
        </Box>
      </CardContent>
      <SaveChartModal widgetContainerElementRef={widgetContainerElementRef} />
    </div>
  )
}
