import { fetchWidget } from '@nrcstat-monorepo/api'
import { useQuery } from 'react-query'

export function useFetchWidget(widgetId: string | null) {
  return useQuery(['widget', widgetId], fetchWidget(`${widgetId}`), {
    enabled: widgetId !== null,
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
  })
}
